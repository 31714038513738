import React, { createContext, useState, useEffect } from 'react';

export const LogoContext = createContext();

export const LogoProvider = ({ children }) => {
  const [selectedLogo, setSelectedLogo] = useState(() => {
    // Load from localStorage or default to 'logway'
    return localStorage.getItem('selectedLogo') || 'logway';
  });

  useEffect(() => {
    // Save to localStorage whenever selectedLogo changes
    localStorage.setItem('selectedLogo', selectedLogo);
  }, [selectedLogo]);

  return (
    <LogoContext.Provider value={{ selectedLogo, setSelectedLogo }}>
      {children}
    </LogoContext.Provider>
  );
};