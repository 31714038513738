import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";
import AttendenceList from "./AttendenceList";


const attendenceroutes = [
  {
    path: "/attendence/list",
    element: <AttendenceList />,
    auth: authRoles.admin, // Only admin can access this route
  }
];

export default attendenceroutes;


// auth: [...authRoles.admin, ...authRoles.sales]