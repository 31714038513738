import React, { useEffect, useState } from "react";
import { Breadcrumb } from "@gull";
import { SimpleCard } from "@gull";
import {
  fetchAllAttendence,
  uploadAttendanceRecords,
} from "app/API/AttendenceAPI";
import moment from "moment";
import { Select } from "antd";
import { Modal, Button } from "react-bootstrap";
import Swal from "sweetalert2";


const MyAttendence = ({employeeDetails}) => {
    const [attendanceList, setAttendanceList] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState("");
    const [status, setStatus] = useState([]);
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
    const [filterType, setFilterType] = useState("today");
    const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
  
    const toggleFilterDropdown = () => setFilterDropdownOpen(!filterDropdownOpen);
  
    const handleFileChange = (event) => {
      setSelectedFile(event.target.files[0]);
    };
  
    const handleUpload = async () => {
      if (selectedFile) {
        try {
          const result = await uploadAttendanceRecords(selectedFile);
          
          if (result.err) {
            // Check if it's a duplicate entry error
            if (result.msg.includes("Duplicate entry")) {
              const date = moment().format("YYYY-MM-DD");
              Swal.fire({
                icon: 'error',
                title: 'Upload Failed',
                text: `Duplicate entry for ${date}`,
                timer: 3000,
                showConfirmButton: false
              });
            } else {
              // For other errors
              Swal.fire({
                icon: 'error',
                title: 'Upload Failed',
                text: result.msg,
                timer: 3000,
                showConfirmButton: false
              });
            }
          } else {
            // Success message
            Swal.fire({
              icon: 'success',
              title: 'Upload Successful',
              text: result.msg,
              timer: 2000,
              showConfirmButton: false
            });
            setModalVisible(false);
            // Assuming fetchData is defined elsewhere in your component
            fetchData(currentPage, itemsPerPage, startDate, endDate, status.join(","));
          }
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Upload Failed',
            text: 'Failed to upload report. try with different report or contact support',
            timer: 3000,
            showConfirmButton: false
          });
        }
      }
    };
  
  
    const handleFilterTypeChange = (type) => {
      setFilterType(type);
      const today = moment().format("YYYY-MM-DD");
      if (type === "month") {
        const currentMonth = moment().month();
        handleMonthChange(currentMonth + 1);
      } else if (type === "today" || type === "custom") {
        setStartDate(today);
        setEndDate(today);
      }
    };
  
    const handleMonthChange = (month) => {
      const year = moment().year();
      setStartDate(
        moment([year, month - 1])
          .startOf("month")
          .format("YYYY-MM-DD")
      );
      setEndDate(
        moment([year, month - 1])
          .endOf("month")
          .format("YYYY-MM-DD")
      );
    };
  
    const handleCustomDateChange = (type, value) => {
      if (type === "startDate") setStartDate(value);
      if (type === "endDate") setEndDate(value);
    };
  
    const fetchData = async (page, limit, startDate, endDate, status) => {
      try {
        const response = await fetchAllAttendence(
          page,
          limit,
          startDate,
          endDate,
          status,
          employeeDetails?.id 
        );
        const attendanceData = response.data.map((record, index) => ({
          ...record,
          index: (page - 1) * limit + index + 1,
        }));
        setAttendanceList(attendanceData);
        setTotalItems(response.total);
      } catch (error) {
        console.error("Error fetching attendance data:", error);
      }
    };
  
    useEffect(() => {
      fetchData(currentPage, itemsPerPage, startDate, endDate, status.join(","));
    }, [currentPage, itemsPerPage, startDate, endDate, status]);
  
    const handlePageChange = (page) => setCurrentPage(page);
  
    const handleItemsPerPageChange = (event) => {
      setItemsPerPage(parseInt(event.target.value, 10));
      setCurrentPage(1);
    };
  
    const handleStatusCheckboxChange = (event, statusValue) => {
      if (event.target.checked) {
        setStatus((prev) => [...prev, statusValue]);
      } else {
        setStatus((prev) => prev.filter((s) => s !== statusValue));
      }
    };
  
    const totalPages = Math.ceil(totalItems / itemsPerPage);
  
    const getVisiblePages = () => {
      const pageRange = 5;
      const start = Math.max(1, currentPage - Math.floor(pageRange / 2));
      const end = Math.min(totalPages, start + pageRange - 1);
      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    };
  
    return (
      <div>  
          <div className="d-flex justify-content-end align-items-center mb-3 gap-3">
            <div className="dropdown">
              <button
                type="button"
                className="btn btn-primary dropdown-toggle"
                onClick={toggleFilterDropdown}
                style={{
                  backgroundColor: "#BBC5EA",
                  borderColor: "#BBC5EA",
                  color: "#000000",
                  height: "39px",
                  width: "82px",
                }}
              >
                Filter&nbsp;
                <i className="fa fa-filter" aria-hidden="true"></i>
              </button>
  
              {filterDropdownOpen && (
                <div
                  className="dropdown-menu"
                  style={{
                    display: "block",
                    position: "absolute",
                    right: 0,
                    left: "auto",
                    width: "320px",
                    padding: "20px",
                    borderRadius: "10px",
                    border: "2px solid #DEE2E6",
                  }}
                >
                  <div style={{ marginBottom: "15px" }}>
                    <label>
                      <input
                        type="radio"
                        name="filterType"
                        value="today"
                        checked={filterType === "today"}
                        onChange={() => handleFilterTypeChange("today")}
                        style={{ marginRight: "8px" }}
                      />
                      Today
                    </label>
                    <br />
                    <label>
                      <input
                        type="radio"
                        name="filterType"
                        value="month"
                        checked={filterType === "month"}
                        onChange={() => handleFilterTypeChange("month")}
                        style={{ marginRight: "8px" }}
                      />
                      Filter by Month
                    </label>
                    <br />
                    <label>
                      <input
                        type="radio"
                        name="filterType"
                        value="custom"
                        checked={filterType === "custom"}
                        onChange={() => handleFilterTypeChange("custom")}
                        style={{ marginRight: "8px" }}
                      />
                      Filter by Custom Date
                    </label>
                  </div>
  
                  {filterType === "month" && (
                    <div>
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Select Month"
                        onChange={handleMonthChange}
                        defaultValue={moment().month() + 1}
                      >
                        {moment.months().map((month, index) => (
                          <option key={index} value={index + 1}>
                            {month}
                          </option>
                        ))}
                      </Select>
                    </div>
                  )}
  
                  {filterType === "custom" && (
                    <div>
                      <label>Start Date:</label>
                      <input
                        type="date"
                        value={startDate}
                        onChange={(e) =>
                          handleCustomDateChange("startDate", e.target.value)
                        }
                        className="form-control"
                      />
                      <label>End Date:</label>
                      <input
                        type="date"
                        value={endDate}
                        onChange={(e) =>
                          handleCustomDateChange("endDate", e.target.value)
                        }
                        className="form-control"
                      />
                    </div>
                  )}
  
                  <div className="status-checkboxes mt-3">
                    <label className="d-flex align-items-center">
                      <input
                        type="checkbox"
                        value="present"
                        checked={status.includes("present")}
                        onChange={(e) => handleStatusCheckboxChange(e, "present")}
                        style={{ marginRight: "8px" }}
                      />
                      Present
                    </label>
                    <label className="d-flex align-items-center">
                      <input
                        type="checkbox"
                        value="absent"
                        checked={status.includes("absent")}
                        onChange={(e) => handleStatusCheckboxChange(e, "absent")}
                        style={{ marginRight: "8px" }}
                      />
                      Absent
                    </label>
                    {/* <label className="d-flex align-items-center">
                      <input
                        type="checkbox"
                        value="on leave"
                        checked={status.includes("on leave")}
                        onChange={(e) => handleStatusCheckboxChange(e, "on leave")}
                        style={{ marginRight: "8px" }}
                      />
                      On Leave
                    </label>
                    <label className="d-flex align-items-center">
                      <input
                        type="checkbox"
                        value="holiday"
                        checked={status.includes("holiday")}
                        onChange={(e) => handleStatusCheckboxChange(e, "holiday")}
                        style={{ marginRight: "8px" }}
                      />
                      Holiday
                    </label> */}
                  </div>
                </div>
              )}
            </div>
          </div>
  
          <table className="table common-table">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Employee ID</th>
                <th>Attendance Date</th>
                <th>Status</th>
                <th>Check-In Time</th>
                <th>Check-Out Time</th>
                <th>Work Hours</th>
              </tr>
            </thead>
            <tbody>
              {attendanceList.length > 0 ? (
                attendanceList.map((item) => (
                  <tr key={item.id}>
                    <td>{item.index}</td>
                    <td>{item.employee_id}</td>
                    <td>{moment(item?.attendance_date).format("DD-MM-YYYY")}</td>
                    <td>{item.status}</td>
                    <td>{item.check_in_time || "-"}</td>
                    <td>{item.check_out_time || "-"}</td>
                    <td>{item.work_hours || "0"}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7">No attendance records available</td>
                </tr>
              )}
            </tbody>
          </table>
  
          <div className="pagination-container">
            <div className="items-per-page">
             
            </div>
  
            <div className="pagination-controls" >
              <button
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
              >
                First
              </button>
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Prev
              </button>
  
              {getVisiblePages().map((page) => (
                <button
                  key={page}
                  className={currentPage === page ? "active" : ""}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </button>
              ))}
  
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
              <button
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              >
                Last
              </button>
            </div>
          </div>
  
          <Modal show={modalVisible} onHide={() => setModalVisible(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Upload Attendance Records</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Select an Excel file to upload attendance records:</p>
              <input
                type="file"
                onChange={handleFileChange}
                accept=".xlsx, .xls"
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setModalVisible(false)}>
                Close
              </Button>
              <Button
                variant="primary"
                onClick={handleUpload}
                disabled={!selectedFile}
              >
                Upload
              </Button>
            </Modal.Footer>
          </Modal>
      </div>
    );
  };

export default MyAttendence