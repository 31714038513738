import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const LeadList = lazy(() => import("./LeadList"));
const CreateLead = lazy(() => import("./CreateNewLead"));

const leadroutes = [
  {
    path: "/lead/list",
    element: <LeadList />,
    auth: [...authRoles.admin, ...authRoles.sales]
  },
  {
    path: "/lead/create",
    element: <CreateLead />,
    auth: [...authRoles.admin, ...authRoles.sales]
  },
];

export default leadroutes;
