import { Breadcrumb, SimpleCard } from "@gull";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  fetchEmployeeById,
  fetchEmployees,
  incrementEmployeeSalary,
  fetchAllSalaryMasters,
  fetchDocumentTypes,
  calculateEmployeeSalary,
} from "app/API/EmployeeAPI";
import swal from "sweetalert2";
import InfiniteScroll from "react-infinite-scroll-component";
import { ClipLoader } from "react-spinners";
import { Button } from "react-bootstrap";
import "../../../../styles/global.css";
import { Tabs, Tab } from "react-bootstrap";
import { Modal, Form, Dropdown } from "react-bootstrap";
import { serverAddress } from "constants";
import currencyCodes from "shared/data/currencyCodes";
import Swal from "sweetalert2";

import EmployeeAboutSection from "./EmployeeAboutSection";
import axios from "axios";
import LeaveApplyModal from "./LeaveApplyModal";
import EmployeeLeaveSection from "./EmployeeLeaveSection";
import EmployeeDocumentSection from "./EmployeeDocumentSection";
import MyAttendence from "./MyAttendence";

import {
  changeEmployeePassword,
  changeEmployeeStatus,
  downloadOfferLetter,
  downloadJoiningLetter,
  downloadTerminationLetter,
} from "app/API/EmployeeAPI";
import MySalary from "./MySalary";

const EmployeeInformation = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const StoredToken = localStorage.getItem("token");
  const [employeeDetails, setEmployeeDetails] = useState(null);
  const [employeeList, setEmployeeList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState("about");
  const [showIncrementModal, setShowIncrementModal] = useState(false);
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [showSalaryModal, setShowSalaryModal] = useState(false);
  const [salaryMonth, setSalaryMonth] = useState("");
  const [salaryYear, setSalaryYear] = useState("");

  const [showLetterModal, setShowLetterModal] = useState(false);
  const [selectedLetterType, setSelectedLetterType] = useState(null);
  const [errors, setErrors] = useState({});

  const [state, setState] = useState({
    rowsPerPage: 10,
    mode: "update",
    page: 0,
    userList: [],
    showEditorDialog: false,
    searchQuery: "",
    dialogValues: null,
    showModal: false,
    showPasswordModal: false, // New state for password update modal
    newPassword: "",
    confirmPassword: "",
    showStatusModal: false,
    newstatus: "",
    showOfferLetterModal: false,
    showJoiningLetterModal: false,
    showTerminationModal: false,
    employee_id: "",
    offer_date: "",
    joining_date: "",
    termination_reason: "",
    last_working_date: "",
    loadingOrders: false,
  });

  const [incrementDetails, setIncrementDetails] = useState({
    employee_id: id ? id : null,
    salary_master_id: "",
    base_salary: "",
    hra: "",
    allowances: "",
    deductions: "",
    net_salary: "",
    gross_salary: "",
    bonus: "",
    currency: "INR", // default value
    type: "", // dropdown value
    work_start_time: "",
    work_end_time: "",
    shift_type: "",
  });

  const [salaryMasters, setSalaryMasters] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const user = JSON.parse(localStorage.getItem("user"));
  const userRoles = user.user_roles;

  const isAdmin = userRoles.some((role) => role.role_name === "admin");

  useEffect(() => {
    const fetchDocumentType = async () => {
      try {
        const types = await fetchDocumentTypes(StoredToken);
        setDocumentTypes(types);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDocumentType();
  }, [id, StoredToken]);

  useEffect(() => {
    if (showLetterModal) {
      const fetchSalaryMasters = async () => {
        try {
          const salaryMasters = await fetchAllSalaryMasters(StoredToken);
          console.log("show salary master response:", salaryMasters);
          setSalaryMasters(salaryMasters);
        } catch (error) {
          console.error("Error fetching salary master data:", error);
        }
      };
      fetchSalaryMasters();
    }
  }, [showLetterModal, StoredToken]);

  const getEmployeeData = async () => {
    try {
      const data = await fetchEmployeeById(id, StoredToken);
      setEmployeeDetails(data);
    } catch (error) {
      swal.fire({
        title: "Error!",
        text: "Failed to fetch employee data.",
        icon: "error",
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    if (id && StoredToken) getEmployeeData();
  }, [id, StoredToken]);

  useEffect(() => {
    const getEmployeeList = async () => {
      try {
        const employees = await fetchEmployees(StoredToken);
        setEmployeeList(employees);
      } catch (error) {
        swal.fire({
          title: "Error!",
          text: "Failed to fetch employees list.",
          icon: "error",
          timer: 1500,
        });
      }
    };
    if (StoredToken) getEmployeeList();
  }, [StoredToken]);

  const handleEmployeeClick = (employeeId) => {
    navigate(`/employee/information/${employeeId}`);
  };

  const filteredEmployeeList = employeeList.filter((employee) => {
    const fullName =
      `${employee.first_name} ${employee.last_name}`.toLowerCase();
    return fullName.includes(searchTerm.toLowerCase());
  });

  // increment form functions

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setIncrementDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSalaryMasterChange = (e) => {
    const selectedId = e.target.value;
    const selectedSalaryMaster = salaryMasters.find(
      (sm) => sm.id === Number(selectedId)
    );

    if (selectedSalaryMaster) {
      setIncrementDetails({
        ...incrementDetails,
        salary_master_id: selectedSalaryMaster.id,
        base_salary: selectedSalaryMaster.base_salary,
        hra: selectedSalaryMaster.hra,
        allowances: selectedSalaryMaster.allowances,
        deductions: selectedSalaryMaster.deductions,
        net_salary: selectedSalaryMaster.net_salary,
        gross_salary: selectedSalaryMaster.gross_salary,
        bonus: selectedSalaryMaster.bonus || "",
        work_start_time: selectedSalaryMaster?.work_start_time || "",
        work_end_time: selectedSalaryMaster?.work_end_time || "",
        shift_type: selectedSalaryMaster?.shift_type || "", // Ensure fallback
      });
    } else {
      console.warn("No matching salary master found.");
    }
  };

  const getLeaveColor = (leaveType) => {
    switch (leaveType) {
      case "SL":
        return "bg-success"; // Green for Sick Leave
      case "WFH":
        return "bg-info"; // Blue for Work from Home
      case "EL":
        return "bg-warning"; // Yellow for Earned Leave
      default:
        return "bg-secondary"; // Gray for others
    }
  };

  const handleModalInputChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(e.target);

    if (name === "offer_date" && value) {
      setErrorMessage(""); // Clear error message when a valid date is entered
    }
  };

  // letters generate

  // single letter button functions

  const handleLetterSelection = (letterType) => {
    setSelectedLetterType(letterType);
    setShowLetterModal(true);
  };

  const closeLetterModal = () => {
    setShowLetterModal(false);
    setSelectedLetterType(null);
    setState((prevState) => ({
      ...prevState,
      offer_date: "",
      joining_date: "",
      last_working_date: "",
      termination_reason: "",
    }));
    setIncrementDetails({
      employee_id: id ? id : null,
      salary_master_id: "",
      base_salary: "",
      hra: "",
      allowances: "",
      deductions: "",
      net_salary: "",
      gross_salary: "",
      bonus: "",
      currency: "INR",
      type: "",
      work_start_time: "",
      work_end_time: "",
      shift_type: "",
    });
  };

  const handleDownloadLetter = async () => {
    if (validateForm()) {
      try {
        let response;
        switch (selectedLetterType) {
          case "offer":
            response = await downloadOfferLetter(
              employeeDetails?.id,
              state.offer_date,
              StoredToken
            );
            break;
          case "joining":
            response = await downloadJoiningLetter(
              employeeDetails?.id,
              state.joining_date,
              StoredToken
            );
            break;
          case "termination":
            response = await downloadTerminationLetter(
              employeeDetails?.id,
              state.last_working_date,
              state.termination_reason,
              StoredToken
            );
            break;
          case "increment":
            response = await incrementEmployeeSalary(
              incrementDetails,
              StoredToken
            );
            break;
        }
        if (response && response.downloadLink) {
          window.open(response.downloadLink);
          Swal.fire({
            title: "Success!",
            text: `${
              selectedLetterType.charAt(0).toUpperCase() +
              selectedLetterType.slice(1)
            } letter generated successfully.`,
            icon: "success",
            timer: 2000,
            showConfirmButton: false,
          });
        } else {
          throw new Error("Download link not found in the response");
        }
        closeLetterModal();
      } catch (error) {
        console.error("Error generating letter:", error);
        Swal.fire({
          title: "Error!",
          text: `Failed to generate ${selectedLetterType} letter. Please try again.`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const validateForm = () => {
    const newErrors = {};

    // Validate based on selected letter type
    if (selectedLetterType === "offer" && !state.offer_date) {
      newErrors.offer_date = "Offer Date is required";
    }

    if (selectedLetterType === "joining" && !state.joining_date) {
      newErrors.joining_date = "Joining Date is required";
    }

    if (selectedLetterType === "termination") {
      if (!state.last_working_date) {
        newErrors.last_working_date = "Last Working Date is required";
      }
      if (!state.termination_reason) {
        newErrors.termination_reason = "Termination Reason is required";
      }
    }

    if (selectedLetterType === "increment") {
      // Validate increment-specific fields
      if (!incrementDetails.salary_master_id) {
        newErrors.salary_master_id = "Salary Master is required";
      }
      if (!incrementDetails.base_salary) {
        newErrors.base_salary = "Base Salary is required";
      } else if (isNaN(incrementDetails.base_salary)) {
        newErrors.base_salary = "Base Salary must be a number";
      }

      if (!incrementDetails.hra) {
        newErrors.hra = "HRA is required";
      } else if (isNaN(incrementDetails.hra)) {
        newErrors.hra = "HRA must be a number";
      }

      if (!incrementDetails.allowances) {
        newErrors.allowances = "Allowances are required";
      } else if (isNaN(incrementDetails.allowances)) {
        newErrors.allowances = "Allowances must be a number";
      }

      if (!incrementDetails.deductions) {
        newErrors.deductions = "Deductions are required";
      } else if (isNaN(incrementDetails.deductions)) {
        newErrors.deductions = "Deductions must be a number";
      }

      if (!incrementDetails.gross_salary) {
        newErrors.gross_salary = "Gross Salary is required";
      } else if (isNaN(incrementDetails.gross_salary)) {
        newErrors.gross_salary = "Gross Salary must be a number";
      }

      if (!incrementDetails.net_salary) {
        newErrors.net_salary = "Net Salary is required";
      } else if (isNaN(incrementDetails.net_salary)) {
        newErrors.net_salary = "Net Salary must be a number";
      }

      if (!incrementDetails.bonus) {
        newErrors.bonus = "Bonus is required";
      } else if (isNaN(incrementDetails.bonus)) {
        newErrors.bonus = "Bonus must be a number";
      }

      if (!incrementDetails.type) {
        newErrors.type = "Type is required";
      }

      if (!incrementDetails.shift_type) {
        newErrors.shift_type = "Shift Type is required";
      }

      if (!incrementDetails.work_start_time) {
        newErrors.work_start_time = "Work Start Time is required";
      }

      if (!incrementDetails.work_end_time) {
        newErrors.work_end_time = "Work End Time is required";
      }
    }

    // Set errors and return the result
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // salary calculation

  const handleSalaryCalculate = () => {
    setShowSalaryModal(true);
  };

  const handleSalaryModalClose = () => {
    setShowSalaryModal(false);
  };

  const handleSalarySubmit = async () => {
    const employee_id = employeeDetails.id; // Assuming employeeDetails is already available
    const month = salaryMonth; // Assuming salaryMonth is already set
    const year = salaryYear; // Assuming salaryYear is already set

    try {
      console.log("Calculating salary for:", { employee_id, month, year });

      // Call the salary calculation API
      const response = await calculateEmployeeSalary(
        employee_id,
        month,
        year,
        StoredToken
      );

      if (response && !response.error) {
        swal.fire({
          icon: "success",
          title: "Success!",
          text: response.msg || "Salary calculated successfully.",
        });

        setSalaryMonth("");
        setSalaryYear("");

        // Optionally handle the response, e.g., update UI with calculated details
      } else {
        swal.fire({
          icon: "error",
          title: "Oops!",
          text: response.msg || "Failed to calculate salary.",
        });
      }
    } catch (error) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while processing the salary calculation.",
      });
      console.error("Error in handleSalarySubmit:", error);
    } finally {
      // Close the modal after handling the process
      handleSalaryModalClose();
    }
  };

  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: 11 },
    (_, index) => currentYear - 5 + index
  );

  console.log("show employee details", employeeDetails);
  return (
    <div>
      <Breadcrumb routeSegments={[{ name: "Employee Information" }]} />
      <div className="row">
        {isAdmin && (
          <div className="col-md-3">
            <div className="employee-list-header">Employee List</div>
            {state.loadingOrders ? (
              <div className="text-center">
                <ClipLoader
                  color="#800080"
                  loading={state.loadingOrders}
                  size={50}
                />
              </div>
            ) : (
              <InfiniteScroll
                dataLength={filteredEmployeeList.length}
                height={700}
                endMessage={
                  <p className="end-message">
                    <b>No more data to load</b>
                  </p>
                }
              >
                {filteredEmployeeList?.map((employee) => (
                  <div
                    key={employee.id}
                    className={`employee-card ${
                      employee.id === id ? "selected" : ""
                    }`}
                    onClick={() => handleEmployeeClick(employee.id)}
                  >
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col-md-3">
                          <div className="avatar-circle1">
                            {employee.first_name.charAt(0).toUpperCase()}{" "}
                            {employee.last_name.charAt(0).toUpperCase()}
                          </div>
                        </div>
                        <div className="col-md-9">
                          <p className="employee-name">
                            {employee.first_name} {employee.last_name}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </InfiniteScroll>
            )}
          </div>
        )}
        <div className={isAdmin ? "col-md-9" : "col-md-12"}>
          <SimpleCard>
            {employeeDetails ? (
              <>
                <div className="employee-details-header d-flex justify-content-between align-items-center">
                  <h4 className="mb-0">
                    {employeeDetails.first_name} {employeeDetails.last_name}
                  </h4>
                  <div className="d-flex align-items-center">
                    {isAdmin && (
                      <>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="primary"
                            id="dropdown-generate-letters"
                            className="d-flex align-items-center justify-content-center"
                            style={{ height: "38px", width: "150px" }}
                          >
                            Generate Letters
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => handleLetterSelection("offer")}
                            >
                              Offer Letter
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleLetterSelection("joining")}
                            >
                              Joining Letter
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() =>
                                handleLetterSelection("termination")
                              }
                            >
                              Termination Letter
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleLetterSelection("increment")}
                            >
                              Increment Letter
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>

                        <Button
                          variant="primary"
                          onClick={handleSalaryCalculate}
                          className="d-flex align-items-center justify-content-center ms-2"
                          style={{ height: "38px", width: "150px" }}
                        >
                          Calculate Salary
                        </Button>
                      </>
                    )}

                    <Button
                      variant="primary"
                      onClick={() => setShowLeaveModal(true)}
                      className="d-flex align-items-center justify-content-center ms-2"
                      style={{ height: "38px", width: "150px" }}
                    >
                      Leave Apply
                    </Button>
                  </div>
                </div>

                <Tabs
                  activeKey={activeTab}
                  onSelect={(key) => setActiveTab(key)}
                  className="mt-3"
                >
                  <Tab eventKey="about" title="About">
                    <EmployeeAboutSection employeeDetails={employeeDetails} />
                  </Tab>

                  <Tab eventKey="leaves" title="Leaves">
                    <div className="leave-allocations">
                      {/* Leave Allocations Section */}
                      {employeeDetails.leave_employee_allocations?.length >
                      0 ? (
                        <div className="leave-allocations-container">
                          {employeeDetails.leave_employee_allocations.map(
                            (allocation) => (
                              <EmployeeLeaveSection
                                key={allocation?.id}
                                allocation={allocation}
                                getLeaveColor={getLeaveColor}
                                employeeDetails={employeeDetails}
                              />
                            )
                          )}
                        </div>
                      ) : (
                        <p>No leave allocations found for this employee.</p>
                      )}

                      {/* Leave Requests Section */}
                      <div className="leave-requests mt-4">
                        <EmployeeLeaveSection
                          leaveRequests={employeeDetails.leave_requests}
                          getLeaveColor={getLeaveColor}
                        />
                      </div>
                    </div>
                  </Tab>

                  <Tab eventKey="documents" title="Documents">
                    <EmployeeDocumentSection
                      documentTypes={documentTypes}
                      employeeDetails={employeeDetails}
                      getEmployeeData={getEmployeeData}
                    />
                  </Tab>

                  <Tab eventKey="Myattendence" title="My Attendence">
                    <MyAttendence employeeDetails={employeeDetails} />
                  </Tab>

                  <Tab eventKey="mysalary" title="My Salary">
                  <MySalary employeeDetails={employeeDetails} />
                  </Tab>
                </Tabs>
              </>
            ) : (
              <p>Loading details...</p>
            )}
          </SimpleCard>
        </div>
      </div>

      {/* single letter modal */}

      <Modal
        show={showLetterModal}
        onHide={closeLetterModal}
        size={selectedLetterType === "increment" ? "lg" : "md"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedLetterType
              ? `${
                  selectedLetterType.charAt(0).toUpperCase() +
                  selectedLetterType.slice(1)
                } Letter`
              : "Generate Letter"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedLetterType === "offer" && (
            <Form.Group controlId="offer_date">
              <Form.Label>Offer Date</Form.Label>
              <Form.Control
                type="date"
                name="offer_date"
                value={state.offer_date}
                onChange={handleModalInputChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.offer_date}
              </Form.Control.Feedback>
            </Form.Group>
          )}
          {selectedLetterType === "joining" && (
            <Form.Group controlId="joining_date">
              <Form.Label>Joining Date</Form.Label>
              <Form.Control
                type="date"
                name="joining_date"
                value={state.joining_date}
                onChange={handleModalInputChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.joining_date}
              </Form.Control.Feedback>
            </Form.Group>
          )}
          {selectedLetterType === "termination" && (
            <>
              <Form.Group controlId="last_working_date">
                <Form.Label>Last Working Date</Form.Label>
                <Form.Control
                  type="date"
                  name="last_working_date"
                  value={state.last_working_date}
                  onChange={handleModalInputChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {errors.last_working_date}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="termination_reason">
                <Form.Label>Termination Reason</Form.Label>
                <Form.Control
                  type="text"
                  name="termination_reason"
                  value={state.termination_reason}
                  onChange={handleModalInputChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {errors.termination_reason}
                </Form.Control.Feedback>
              </Form.Group>
            </>
          )}
          {selectedLetterType === "increment" && (
            <Form>
              <Form.Group controlId="salary_master_id">
                <Form.Label>Salary Master:</Form.Label>
                <Form.Control
                  as="select"
                  value={incrementDetails.salary_master_id}
                  onChange={handleSalaryMasterChange}
                >
                  <option value="">Select Salary Master</option>
                  {salaryMasters?.map((sm) => (
                    <option key={sm.id} value={sm.id}>
                      {sm.job_title}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.salary_master_id}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="type">
                <Form.Label>Type:</Form.Label>
                <Form.Control
                  as="select"
                  name="type"
                  value={incrementDetails.type}
                  onChange={handleInputChange}
                  isInvalid={!!errors.type}
                >
                  <option value="">Select Type</option>
                  <option value="increment">Increment</option>
                  <option value="promotion">Promotion</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.type}
                </Form.Control.Feedback>
              </Form.Group>

              {[
                { name: "base_salary", label: "Base Salary" },
                { name: "hra", label: "HRA" },
                { name: "allowances", label: "Allowances" },
                { name: "deductions", label: "Deductions" },
                { name: "gross_salary", label: "Gross Salary" },
                { name: "net_salary", label: "Net Salary" },
                { name: "bonus", label: "Bonus (Optional)" },
              ].map((field) => (
                <Form.Group controlId={field.name} key={field.name}>
                  <Form.Label>{field.label}:</Form.Label>
                  <Form.Control
                    type="number"
                    name={field.name}
                    value={incrementDetails[field.name]}
                    onChange={handleInputChange}
                    isInvalid={!!errors[field.name]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors[field.name]}
                  </Form.Control.Feedback>
                </Form.Group>
              ))}

              {["work_start_time", "work_end_time"].map((field) => (
                <Form.Group controlId={field} key={field}>
                  <Form.Label>{field.replace("_", " ")}:</Form.Label>
                  <Form.Control
                    type="time"
                    name={field}
                    value={incrementDetails[field]}
                    onChange={(e) =>
                      setIncrementDetails((prevDetails) => ({
                        ...prevDetails,
                        [field]: e.target.value + ":00",
                      }))
                    }
                    isInvalid={!!errors[field]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors[field]}
                  </Form.Control.Feedback>
                </Form.Group>
              ))}

              <Form.Group controlId="shift_type">
                <Form.Label>Shift Type:</Form.Label>
                <Form.Control
                  type="text"
                  name="shift_type"
                  value={incrementDetails.shift_type}
                  onChange={handleInputChange}
                  isInvalid={!!errors.shift_type}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.shift_type}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="currency">
                <Form.Label>Currency:</Form.Label>
                <Form.Control
                  as="select"
                  name="currency"
                  value={incrementDetails.currency}
                  onChange={handleInputChange}
                  isInvalid={!!errors.currency}
                >
                  <option value="">Select Currency</option>
                  {currencyCodes?.map((currency, index) => (
                    <option
                      key={`${currency.AlphabeticCode}-${index}`}
                      value={currency.AlphabeticCode}
                    >
                      {currency.Currency} ({currency.AlphabeticCode})
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.currency}
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeLetterModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleDownloadLetter}>
            Generate{" "}
            {selectedLetterType
              ? `${
                  selectedLetterType.charAt(0).toUpperCase() +
                  selectedLetterType.slice(1)
                } Letter`
              : "Letter"}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* salary calculate modal */}

      <Modal show={showSalaryModal} onHide={handleSalaryModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Calculate Salary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="salaryMonth">
              <Form.Label>Month</Form.Label>
              <Form.Control
                as="select"
                value={salaryMonth}
                onChange={(e) => setSalaryMonth(e.target.value)}
                aria-label="Select month for salary calculation"
              >
                <option value="">Select Month</option>
                {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                  <option key={month} value={month}>
                    {new Date(0, month - 1).toLocaleString("default", {
                      month: "long",
                    })}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="salaryYear" className="mt-3">
              <Form.Label>Year</Form.Label>
              <Form.Control
                as="select"
                value={salaryYear}
                onChange={(e) => setSalaryYear(e.target.value)}
                aria-label="Select year for salary calculation"
              >
                <option value="">Select Year</option>
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
          <div className="mt-3">
            <h5>Salary Breakup:</h5>
            {employeeDetails?.employee_salary_mappers?.[0] && (
              <ul className="list-unstyled">
                <li className="d-flex justify-content-between">
                  <span>Allowances:</span>
                  <span>
                    {employeeDetails.employee_salary_mappers[0].allowances}
                  </span>
                </li>
                <li className="d-flex justify-content-between">
                  <span>Base Salary:</span>
                  <span>
                    {employeeDetails.employee_salary_mappers[0].base_salary}
                  </span>
                </li>
                <li className="d-flex justify-content-between">
                  <span>Bonus:</span>
                  <span>
                    {employeeDetails.employee_salary_mappers[0].bonus}
                  </span>
                </li>
                <li className="d-flex justify-content-between">
                  <span>Currency:</span>
                  <span>
                    {employeeDetails.employee_salary_mappers[0].currency}
                  </span>
                </li>
                <li className="d-flex justify-content-between">
                  <span>Deductions:</span>
                  <span>
                    {employeeDetails.employee_salary_mappers[0].deductions}
                  </span>
                </li>
                <li className="d-flex justify-content-between">
                  <span>Gross Salary:</span>
                  <span>
                    {employeeDetails.employee_salary_mappers[0].gross_salary}
                  </span>
                </li>
                <li className="d-flex justify-content-between">
                  <span>HRA:</span>
                  <span>{employeeDetails.employee_salary_mappers[0].hra}</span>
                </li>
                <li className="d-flex justify-content-between">
                  <span>Net Salary:</span>
                  <span>
                    {employeeDetails.employee_salary_mappers[0].net_salary}
                  </span>
                </li>
              </ul>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleSalaryModalClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSalarySubmit}
            disabled={!salaryMonth || !salaryYear}
          >
            Calculate
          </Button>
        </Modal.Footer>
      </Modal>

      {/* leave modal */}

      <LeaveApplyModal
        show={showLeaveModal}
        onClose={() => setShowLeaveModal(false)}
        employeeId={id}
        token={StoredToken}
        employeeDetails={employeeDetails}
      />
    </div>
  );
};

export default EmployeeInformation;
