import axios from "axios";
import { serverAddress } from "constants";

const axiosInstance = axios.create({
  baseURL: serverAddress,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.data) {
      throw new Error(error.response.data.message || "Request failed");
    }
    throw error;
  }
);
const config = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
});

const token = localStorage.getItem("token");

export const fetchClientNameList = async (
  token,
  page ,
  limit ,
  search = ""
) => {
  try {
    const params = {
      page,
      limit,
      search,
    };
    const response = await axiosInstance.get("/client/names/list", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    });
    return response.data.company_list;
  } catch (error) {
    throw error;
  }
};

export const fetchClientLead = async (token, uuid) => {
  try {
    const response = await axiosInstance.get(`/lead/client/${uuid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveQuotation = async (token, quotationData,selectedLogo) => {
  try {
    const response = await axiosInstance.post(
      "/sale",
      { data: quotationData, token,selectedLogo },
      config(token)
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSaleByUUID = async (uuid, token) => {
  try {
    const response = await axiosInstance.get(
      `/sale?uuid=${uuid}`,
      config(token)
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAllQuotation = async (token, page, limit, search) => {
  try {
    const params = {
      page: page,
      limit: limit,
      search: search,
    };
    const response = await axiosInstance.get("/sale/all", {
      ...config(token),
      params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteSaleById = async (saleId) => {
  try {
    const response = await axiosInstance.delete(
      `/sale/rev/${saleId}`,
      config(token)
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateQuotation = async (token, quotationData,selectedLogo) => {
  try {
    const response = await axiosInstance.put(
      "/sale",
      { data: quotationData, token,selectedLogo },
      config(token)
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const downloadSale = async (link,selectedLogo) => {
  try {
    const requestBody = {
      token: token,
      link: link,
      selectedLogo: selectedLogo,
    };
    const response = await axiosInstance.post(
      "/sale/download",
      requestBody,
      config(token)
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default axiosInstance;
