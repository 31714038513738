// import "../fake-db";
import "../styles/app/app.scss";
import Auth from "./auth/Auth";
import React from "react";
import routes from "./routes";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <Auth>
      <RouterProvider router={routes} />
      <ToastContainer />
    </Auth>
  );
}

export default App;
