import swal from "sweetalert2";
import { useEffect, useState } from "react";
import { Breadcrumb } from "@gull";
import ReactPaginate from "react-paginate";
// import { Dropdown } from "react-bootstrap";
import { MdMoreVert } from "react-icons/md";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import { SimpleCard } from "@gull";
import { Dropdown, Menu, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import {
  changeEmployeePassword,
  changeEmployeeStatus,
  downloadOfferLetter,
  downloadJoiningLetter,
  downloadTerminationLetter,
} from "app/API/EmployeeAPI";
import {
  fetchEmployees,
  deleteEmployee,
  updateEmployee,
  createEmployee,
} from "app/API/EmployeeAPI";
import EmployeeModal from "./EmployeeModal";
import { useNavigate } from "react-router-dom";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
// import { C } from "@fullcalendar/core/internal-common";
import "../../../styles/global.css";

const EmployeesList = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [filter, setFilter] = useState("");
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [employeeEditData, setEmployeeEditData] = useState({
    first_name: "",
    last_name: "",
    address: "",
    email_address: "",
    designation: "",
    department: "",
    phone_number: "",
    base_salary: "",
    hra: "",
    allowances: "",
    gross_salary: "",
    net_salary: "",
    bonus: "",
    work_start_time: "",
    work_end_time: "",
    shift_type: "",
    status: "",
    deductions: "",
  });

  const [state, setState] = useState({
    rowsPerPage: 10,
    mode: "update",
    page: 0,
    userList: [],
    showEditorDialog: false,
    searchQuery: "",
    dialogValues: null,
    showModal: false,
    updatedEmployeeData: {
      id: "",
      first_name: "",
      last_name: "",
      email_address: "",
      designation: "",
      department: "",
      address: "",
      phone_number: "",
    },
    showPasswordModal: false, // New state for password update modal
    newPassword: "",
    confirmPassword: "",
    showStatusModal: false,
    newstatus: "",
    showOfferLetterModal: false,
    showJoiningLetterModal: false,
    showTerminationModal: false,
    employee_id: "",
    offer_date: "",
    joining_date: "",
    termination_reason: "",
    last_working_date: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const designationFilterOptions = [
    { label: "All Designations", value: "" },
    { label: "Admin", value: "admin" },
    { label: "Sales", value: "sales" },
    { label: "Purchase", value: "purchase" },
    { label: "Order Handling and Production", value: "orders" },
    { label: "Store", value: "store" },
    { label: "Store & Purchase", value: "storepurchase" },
  ];

  // const handleSearch = ({ target: { name, value } }) => {
  //   setState((prevState) => ({ ...prevState, [name]: value }));
  //   const filteredList = userList?.filter(
  //     (user) =>
  //       user.first_name.toLowerCase().includes(value.toLowerCase()) ||
  //       user.last_name.toLowerCase().includes(value.toLowerCase())
  //   );

  //   if (filter) {
  //     const filteredByDesignation = filteredList?.filter(
  //       (user) => user.designation === filter
  //     );
  //     setFilteredUserList(filteredByDesignation);
  //   } else {
  //     setFilteredUserList(filteredList);
  //   }
  // };

  useEffect(() => {
    const token = localStorage.getItem("token");
    fetchEmployees(token)
      .then((employees) => {
        setState((prevState) => ({
          ...prevState,
          userList: employees,
        }));
      })
      .catch((error) => {
        console.error("Error fetching data:");
      });
  }, []);

  const updatePageData = () => {
    const token = localStorage.getItem("token");

    fetchEmployees(token).then((employees) => {
      // console.log(employees);
      setState((prevState) => ({
        ...prevState,
        userList: employees,
      }));
    });
  };

  // const handleSearch = ({ target: { value } }) => {
  //   setState((prevState) => ({ ...prevState, searchQuery: value }));
  // };

  const handlePageClick = (data) => {
    let page = data.selected;
    setState((prevState) => ({ ...prevState, page }));
  };

  const toggleEditorDialog = (arg) => {
    if (arg && arg.toString())
      setState((prevState) => ({
        ...prevState,
        showEditorDialog: arg,
        dialogValues: null,
      }));
    else
      setState((prevState) => ({
        ...prevState,
        showEditorDialog: !state.showEditorDialog,
        dialogValues: null,
      }));
  };

  const handleEditContact = (user) => {
    // console.log("show anything:", user);
    openModal(user); // Call the openModal function and pass the user data
  };

  const handleDeleteContact = (user) => {
    const token = localStorage.getItem("token");

    deleteEmployee(user.id, token)
      .then(() => {
        // Filter out the deleted user from the userList
        const updatedUserList = state?.userList?.filter(
          (currentUser) => currentUser.id !== user.id
        );
        setState((prevState) => ({ ...prevState, userList: updatedUserList }));
        swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          type: "success",
          icon: "success",
          timer: 1500,
        });
      })
      .catch((error) => {
        console.error("Error deleting user:");
        swal.fire({
          title: "Error!",
          text: "Failed to delete user.",
          type: "error",
          icon: "error",
          timer: 1500,
        });
      });
  };

  const employeeNameFormatter = (cell, row) => {
    return (
      <span
        className="company-name-cell"
        onClick={() => navigate(`/employee/information/:${row.uuid}`)}
        // style={textStyle}
        // style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
      >
        {row.first_name} {row.last_name}
      </span>
    );
  };

  

  const menu = (record) => (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          swal
            .fire({
              title: "Are you sure?",
              text: "You won't be able to revert this!",
              icon: "warning",
              type: "question",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, delete it!",
              cancelButtonText: "No",
            })
            .then((result) => {
              if (result.value) {
                handleDeleteContact(record);
              } else {
                swal.fire("Cancelled!", "Permission denied.", "error");
              }
            });
        }}
      >
        Delete employee
      </Menu.Item>
      <Menu.Item key="2" onClick={() => handleEditContact(record)}>
        Edit employee
      </Menu.Item>
      <Menu.Item key="3" onClick={() => handleUpdatePasswordModal(record)}>
        Update Password
      </Menu.Item>
      {/* <Menu.Item key="4" onClick={() => handleUpdateStatusModal(record)}>
        Update Status
      </Menu.Item> */}
    </Menu>
  );

  const handleUpdatePasswordModal = (user) => {
    setState((prevState) => ({
      ...prevState,
      updatedEmployeeData: { ...user },
      showPasswordModal: true,
      newstatus: "",
    }));
  };

  const closePasswordModal = () => {
    setState((prevState) => ({
      ...prevState,
      showPasswordModal: false,
      newstatus: "",
    }));
  };
  // const handleUpdateStatusModal = (user) => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     updatedEmployeeData: { ...user },
  //     showStatusModal: true,
  //     newstatus: "",
  //   }));
  // };
  // const closeStatusModal = () => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     showStatusModal: false,
  //     newstatus: "",
  //   }));
  // };
  const handleOfferLetterModal = (user) => {
    setState((prevState) => ({
      ...prevState,
      updatedEmployeeData: { ...user },
      showOfferLetterModal: true,
      employee_id: user.id,
      offer_date: "",
    }));
  };
  const closeOfferLetterModal = () => {
    setState((prevState) => ({
      ...prevState,
      showOfferLetterModal: false,
      employee_id: "",
      offer_date: "",
    }));
  };
  const handleDownloadOfferLetter = async () => {
    if (!state.offer_date) {
      setErrorMessage("Offer Date is required.");
      return;
    }
    setErrorMessage("");
    const response = await downloadOfferLetter(
      state.employee_id,
      state.offer_date,
      token
    );
    window.open(response.downloadLink);
  };
  const handleJoiningLetterModal = (user) => {
    setState((prevState) => ({
      ...prevState,
      updatedEmployeeData: { ...user },
      showJoiningLetterModal: true,
      employee_id: user.id,
      joining_date: "",
    }));
  };
  const closeJoiningLetterModal = () => {
    setState((prevState) => ({
      ...prevState,
      showJoiningLetterModal: false,
      employee_id: "",
      joining_date: "",
    }));
  };
  const handleDownloadJoiningLetter = async () => {
    if (!state.joining_date) {
      setErrorMessage("Joining Date is required.");
      return;
    }
    setErrorMessage("");
    const response = await downloadJoiningLetter(
      state.employee_id,
      state.joining_date,
      token
    );
    console.log(response);
    window.open(response.downloadLink);
  };
  const handleTerminationModal = (user) => {
    setState((prevState) => ({
      ...prevState,
      updatedEmployeeData: { ...user },
      showTerminationModal: true,
      employee_id: user.id,
      last_working_date: "",
      termination_reason: "",
    }));
  };
  const closeTerminationModal = () => {
    setState((prevState) => ({
      ...prevState,
      showTerminationModal: false,
      employee_id: "",
      last_working_date: "",
      termination_reason: "",
    }));
  };
  const handleDownloadTerminationLetter = async () => {
    if (!state.last_working_date) {
      setErrorMessage("Last Working Date is required.");
      return;
    }
    setErrorMessage("");
    if (!state.termination_reason) {
      setErrorMessage("Termination Reason is required.");
      return;
    }
    setErrorMessage("");
    const response = await downloadTerminationLetter(
      state.employee_id,
      state.last_working_date,
      state.termination_reason,
      token
    );
    console.log(response);
    window.open(response.downloadLink);
  };
  useEffect(() => {
    updatePageData();
  }, []);

  // Function to open the modal
  const openModal = (user) => {
    setState((prevState) => ({
      ...prevState,
      showModal: true,
      updatedEmployeeData: { ...user },
    }));
  };

  // Function to close the modal
  const closeModal = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: false,
      updatedEmployeeData: {
        first_name: "",
        last_name: "",
        email_address: "",
        designation: "",
        department: "",
        address: "",
        phone_number: "",
      },
    }));
  };

  const handleModalInputChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(e.target);

    if (name === "offer_date" && value) {
      setErrorMessage(""); // Clear error message when a valid date is entered
    }
  };

  const handleUpdatePassword = async () => {
    const { updatedEmployeeData, newPassword, confirmPassword } = state;

    if (newPassword !== confirmPassword) {
      swal.fire({
        title: "Error!",
        text: "Passwords do not match.",
        type: "error",
        icon: "error",
        timer: 1500,
      });
      return;
    }

    const payload = {
      id: updatedEmployeeData.id,
      password: newPassword,
      token: localStorage.getItem("token"),
    };

    try {
      await changeEmployeePassword(payload, token);
      // Handle success
      swal.fire({
        title: "Success!",
        text: "Password updated successfully.",
        type: "success",
        icon: "success",
        timer: 1500,
      });
      closeModal(); // Close the password modal
    } catch (error) {
      swal.fire({
        title: "Error!",
        text: "Failed to update password.",
        type: "error",
        icon: "error",
        timer: 1500,
      });
    }
  };

  const handleCreateEmployee = async () => {
    const usertoken = localStorage.getItem("token");
    const { updatedEmployeeData } = state;

    try {
      await createEmployee(updatedEmployeeData, usertoken);
      updatePageData(); // Fetch updated employee list
      closeModal(); // Close the modal after successful creation
      swal.fire({
        title: "Success!",
        text: "Employee added successfully.",
        type: "success",
        icon: "success",
        timer: 1500,
      });
    } catch (error) {
      swal.fire({
        title: "Error!",
        text: "Failed to add employee.",
        type: "error",
        icon: "error",
        timer: 1500,
      });
    }
  };

  const handleEmployeeNavigate = () => {
    // Replace "/add-client" with the actual path to your add client component
    navigate("/employee/create");
  };

  const handleUpdateEmployee = async () => {
    const usertoken = localStorage.getItem("token");
    const { id, ...updatedData } = state.updatedEmployeeData;
    const dataToBeUpdated = {
      ...employeeEditData,
    };
    console.log(dataToBeUpdated);

    updatedData.id = id;
    try {
      if (state.mode === "update") {
        await updateEmployee(dataToBeUpdated, usertoken);
      } else if (state.mode === "create") {
        await createEmployee(updatedData, usertoken);
      }

      updatePageData(); // Fetch updated employee list
      closeModal(); // Close the modal after successful update
      swal.fire({
        title: "Success!",
        text:
          state.mode === "update"
            ? "Employee updated successfully."
            : "Employee added successfully.",
        type: "success",
        icon: "success",
        timer: 1500,
      });
    } catch (error) {
      console.error(
        `Error ${state.mode === "update" ? "updating" : "adding"} employee:`,
        error
      );
      swal.fire({
        title: "Error!",
        text:
          state.mode === "update"
            ? "Failed to update employee."
            : "Failed to add employee.",
        type: "error",
        icon: "error",
        timer: 1500,
      });
    }
  };

  const handleDesignationFilter = (e) => {
    const selectedFilter = e.key;
    setFilter(selectedFilter);
    const filteredList = userList?.filter(
      (user) => user.designation === selectedFilter
    );
    setFilteredUserList(filteredList);
  };

  const designationFilterMenu = (
    <Menu onClick={handleDesignationFilter}>
      {designationFilterOptions?.map((option) => (
        <Menu.Item key={option.value}>{option.label}</Menu.Item>
      ))}
    </Menu>
  );

  const paginationOptions = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    totalSize: state?.userList?.length,
  };
  let { userList } = state;
  let { SearchBar } = Search;

  // console.log("show user list:", userList);

  const filteredUsers =
    filteredUserList?.length > 0
      ? filteredUserList
      : userList?.filter(
          (user) =>
            user.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.last_name.toLowerCase().includes(searchQuery.toLowerCase())
        );

  const indexOfLastUser = currentPage * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1); // Reset to first page on items per page change
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getVisiblePages = () => {
    const pageRange = 5; // Number of pages to display
    const start = Math.max(1, currentPage - Math.floor(pageRange / 2));
    const end = Math.min(totalPages, start + pageRange - 1);
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to first page when starting a new search
  };

  const renderStatus = (status) => {
    let labelColor, textColor, label;
    switch (status) {
      case "pending":
        labelColor = "#FFFBEA";
        textColor = "#FFC107";
        label = "Pending";
        break;
      case "active":
        labelColor = "#E6FFED";
        textColor = "#28A745";
        label = "Active";
        break;
      case "inactive":
        labelColor = "#E0E0E0";
        textColor = "#6C757D";
        label = "Inactive";
        break;
      case "terminated":
        labelColor = "#FFEBE6";
        textColor = "#DC3545";
        label = "Terminated";
        break;
      default:
        labelColor = "#E0E0E0";
        textColor = "#6C757D";
        label = "No Status";
        break;
    }
    return (
      <span
        style={{
          backgroundColor: labelColor,
          color: textColor,
          padding: "5px 10px",
          borderRadius: "10px",
          fontWeight: "500",
        }}
      >
        {label}
      </span>
    );
  };

  const buttonStyle = (bgColor) => ({
    backgroundColor: bgColor,
    width: "30px",
    height: "30px",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
  });

  return (
    <>
      <div>
        <Breadcrumb
          routeSegments={[
            { name: "Dashboard", path: "/dashboard/v1" },
            { name: "Employees list" },
          ]}
        />
        <SimpleCard title="">
          <ToolkitProvider
            striped
            keyField="id"
            data={userList}
            search
          >
            {(props) => (
              <>
                <div className="d-flex justify-content-between align-items-center mb-3 ">
                  {/* <span className="mb-2 me-1">Search:</span> */}
                  {/* <SearchBar
                    {...props.searchProps}
                    style={{ width: "290px", height: "38px" }}
                  /> */}
                  <div className="w-96">
                    <input
                      type="text"
                      placeholder="Search by first or last name"
                      className="search-bar"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </div>

                  <div className="d-flex justify-content-between mb-3 align-items-center">
                    <Dropdown
                      className="me-2"
                      overlay={designationFilterMenu}
                      trigger={["click"]}
                    >
                      <Button variant="primary" className="mx-2 py-2">
                        Filter <DownOutlined />
                      </Button>
                    </Dropdown>
                    <Button
                      variant="success"
                      style={{
                        backgroundColor: "purple",
                        borderColor: "purple",
                        color: "#fff",
                      }}
                      className="py-2"
                      onClick={handleEmployeeNavigate}
                    >
                      <i className="i-Add-User mr-1"></i> Add Employee
                    </Button>
                  </div>
                </div>

                <table className="table common-table">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Name</th>
                      <th>Department</th>
                      <th>Designation</th>
                      <th>Email Address</th>
                      <th>Phone Number</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentUsers.length > 0 ? (
                      currentUsers.map((user, index) => (
                        <tr key={user?.id}>
                          <td
                            onClick={() =>
                              navigate(`/employee/information/${user.id}`)
                            }
                          >
                            {indexOfFirstUser + index + 1}
                          </td>
                          <td
                            onClick={() =>
                              navigate(`/employee/information/${user.id}`)
                            }
                          >{`${user?.first_name} ${user?.last_name}`}</td>
                          <td
                            onClick={() =>
                              navigate(`/employee/information/${user.id}`)
                            }
                          >
                            {user?.department}
                          </td>
                          <td
                            onClick={() =>
                              navigate(`/employee/information/${user.id}`)
                            }
                          >
                            {user?.designation}
                          </td>
                          <td
                            onClick={() =>
                              navigate(`/employee/information/${user.id}`)
                            }
                          >
                            {user?.email_address}
                          </td>
                          <td
                            onClick={() =>
                              navigate(`/employee/information/${user.id}`)
                            }
                          >
                            {user?.phone_number}
                          </td>
                          <td
                            onClick={() =>
                              navigate(`/employee/information/${user.id}`)
                            }
                          >
                            {renderStatus(user.status)}
                          </td>
                          
                          {/* Actions Column */}
                          <td>
                            <Space size="middle">
                              <Dropdown overlay={menu(user)}>
                                <a
                                  className="ant-dropdown-link"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  Options <DownOutlined />
                                </a>
                              </Dropdown>
                            </Space>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="9">No data available</td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <div className="pagination-container">
                  <div className="items-per-page">
                    <label>Items per page: </label>
                    <select
                      value={itemsPerPage}
                      onChange={handleItemsPerPageChange}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                  </div>

                  <div className="pagination-controls">
                    <button
                      onClick={() => handlePageChange(1)}
                      disabled={currentPage === 1}
                    >
                      First
                    </button>
                    <button
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Prev
                    </button>

                    {getVisiblePages().map((page) => (
                      <button
                        key={page}
                        className={currentPage === page ? "active" : ""}
                        onClick={() => handlePageChange(page)}
                      >
                        {page}
                      </button>
                    ))}

                    <button
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                    <button
                      onClick={() => handlePageChange(totalPages)}
                      disabled={currentPage === totalPages}
                    >
                      Last
                    </button>
                  </div>
                </div>
              </>
            )}
          </ToolkitProvider>
        </SimpleCard>

        {Object.values(state.updatedEmployeeData).some(
          (field) => field !== ""
        ) && (
          <EmployeeModal
            showModal={state.showModal}
            handleCloseModal={closeModal}
            handleSubmit={
              state.mode === "update"
                ? handleUpdateEmployee
                : handleCreateEmployee
            }
            mode={state.mode}
            employeeData={state.updatedEmployeeData}
            setEmployeeEditData={setEmployeeEditData}
            employeeEditData={employeeEditData}
          />
        )}

        <Modal
          show={state.showPasswordModal}
          onHide={closePasswordModal}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <label htmlFor="newPassword" className="form-label">
                New Password
              </label>
              <input
                type="password"
                className="form-control"
                id="newPassword"
                name="newPassword"
                value={state.newPassword}
                onChange={handleModalInputChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="confirmPassword" className="form-label">
                Confirm Password
              </label>
              <input
                type="password"
                className="form-control"
                id="confirmPassword"
                name="confirmPassword"
                value={state.confirmPassword}
                onChange={handleModalInputChange}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closePasswordModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleUpdatePassword}>
              Update Password
            </Button>
          </Modal.Footer>
        </Modal>
        
        <Modal
          show={state.showOfferLetterModal}
          onHide={closeOfferLetterModal}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Offer Letter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <label htmlFor="employee_id" className="form-label">
                Employee Id
              </label>
              <input
                type="text"
                id="employee_id"
                name="employee_id"
                className="form-control"
                value={`${state.updatedEmployeeData.first_name} ${state.updatedEmployeeData.last_name}`}
                readOnly
                style={{ cursor: "not-allowed" }}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="offer_date" className="form-label">
                Offer Date
              </label>
              <input
                type="date"
                className="form-control"
                id="offer_date"
                name="offer_date"
                value={state.offer_date}
                onChange={handleModalInputChange}
                required
              />
              {errorMessage && (
                <div className="text-danger mt-1">{errorMessage}</div> // Error message
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeOfferLetterModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleDownloadOfferLetter}>
              Generate Offer
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={state.showJoiningLetterModal}
          onHide={closeJoiningLetterModal}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Joining Letter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <label htmlFor="employee_id" className="form-label">
                Employee Id
              </label>
              <input
                type="text"
                id="employee_id"
                name="employee_id"
                className="form-control"
                value={`${state.updatedEmployeeData.first_name} ${state.updatedEmployeeData.last_name}`}
                readOnly
                style={{ cursor: "not-allowed" }}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="joining_date" className="form-label">
                Joining Date
              </label>
              <input
                type="date"
                className="form-control"
                id="joining_date"
                name="joining_date"
                value={state.joining_date}
                onChange={handleModalInputChange}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeJoiningLetterModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleDownloadJoiningLetter}>
              Generate Joining Letter
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={state.showTerminationModal}
          onHide={closeTerminationModal}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Termination Letter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <label htmlFor="employee_id" className="form-label">
                Employee Id
              </label>
              <input
                type="text"
                id="employee_id"
                name="employee_id"
                className="form-control"
                value={`${state.updatedEmployeeData.first_name} ${state.updatedEmployeeData.last_name}`}
                readOnly
                style={{ cursor: "not-allowed" }}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="last_working_date" className="form-label">
                Last Working Date
              </label>
              <input
                type="date"
                className="form-control"
                id="last_working_date"
                name="last_working_date"
                value={state.last_working_date}
                onChange={handleModalInputChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="termination_reason" className="form-label">
                Termination Reason
              </label>
              <input
                type="text"
                className="form-control"
                id="termination_reason"
                name="termination_reason"
                value={state.termination_reason}
                onChange={handleModalInputChange}
                required
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeTerminationModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleDownloadTerminationLetter}>
              Generate Termination Letter
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default EmployeesList;
