import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";
import AssignMonthlyHoliday from "./AssignMonthlyHoliday";

const holidayroutes = [
  {
    path: "/month/holidays/list",
    element: <AssignMonthlyHoliday />,
    auth: authRoles.admin, // Only admin can access this route
  }
];

export default holidayroutes;


// auth: [...authRoles.admin, ...authRoles.sales]