import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";
import EmployeesList from "./EmployeesList";
import EmployeeCreate from "./EmployeeCreate";
import EmployeeInformation from "./EmployeeProfile/EmployeeInformation";

const employeeroutes = [
  {
    path: "/employee/list",
    element: <EmployeesList />,
    auth: authRoles.admin, // Only admin can access this route
  },
  {
    path: "/employee/create",
    element: <EmployeeCreate />,
    auth: authRoles.admin, // Only admin can access this route
  },
  {
    path: "/employee/information/:id",
    element: <EmployeeInformation />,
    auth: authRoles.admin, // Only admin can access this route
  },
];

export default employeeroutes;


// auth: [...authRoles.admin, ...authRoles.sales]