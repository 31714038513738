import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const JobOrderList = lazy(() => import("./JobOrderList"));
const CreateJobOrder = lazy(() => import("./JobOrderCreate/CreateJobOrder"));
const OrderInformation = lazy(() => import("./OrderInformation"));

const orderroutes = [
  {
    path: "/order/list",
    element: <JobOrderList />,
    auth: [...authRoles.admin, ...authRoles.sales, ...authRoles.orders],
  },

  {
    path: "/order/create",
    element: <CreateJobOrder />,
    auth: [...authRoles.admin, ...authRoles.sales, ...authRoles.orders],
  },
  {
    path: "/order/information/:uuid",
    element: <OrderInformation />,
    auth: [...authRoles.admin, ...authRoles.sales, ...authRoles.orders],
  },
];

export default orderroutes;
