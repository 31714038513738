import axios from "axios";
import { serverAddress } from "constants";

const axiosInstance = axios.create({
  baseURL: serverAddress,
});

export const AddLead = async (data, token) => {
  try {
    const response = await axiosInstance.post("/lead/create", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        ContentType: `application/json`,
      },
    });

    if (response.status === 200 && !response.data.error) {
      return response.data; // Return the response if there's no error
    } else {
      throw new Error("Error adding lead");
    }
  } catch (error) {
    throw error;
  }
};

export const orderDetails = async (uuid, token) => {
  try {
    const response = await axiosInstance.get(
      `/order/job_order_details/${uuid}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          ContentType: `application/json`,
        },
      }
    );

    if (response.status === 200 && !response.data.error) {
      return response.data; // Return the response if there's no error
    } else {
      throw new Error("Error fetching leads");
    }
  } catch (error) {
    throw error;
  }
};

export const fetchLeadListApi = async (
  options,
  uuid = null,
  token,
  handledBy ="",
  page = 0,
  limit = 0,
  search = ""
) => {
  const filterStatus = options
    ? Object.keys(options)
        .filter((option) => options[option])
        .join(",")
    : "";
    const filterStatusArray = options
    ? Object.keys(options).filter((option) => options[option])
    : [];


    console.log("show filter status fast", options);
  // Construct the base URL with query parameters
  let url = `/lead/list?page=${page}&limit=${limit}&filterStatus=${filterStatus}&search=${search}&empID=31&handledBy=${handledBy ? handledBy : ""}`;

  // Conditionally add handledBy if it has a value (non-null and non-empty)
  
  // If uuid is provided, adjust the URL
  if (uuid) {
    url = `/lead/client/${uuid}?page=1&limit=5&filterStatus=${filterStatus}`;

    try {
      // Send the get request with query parameters in the URL
      const response = await axiosInstance.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Error fetching client list:"
      );
    }
  }

  try {
    // Send the POST request with query parameters in the URL
    const response = await axiosInstance.post(url, filterStatusArray, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message || "Error fetching lead list:");
  }
};

export const fetchLeadDetails = async (id, token) => {
  try {
    const response = await axiosInstance.get(`/sale/lead/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        ContentType: `application/json`,
      },
    });

    if (response.status === 200 && !response.data.error) {
      return response.data; // Return the response if there's no error
    } else {
      throw new Error("Error fetching lead details");
    }
  } catch (error) {
    throw error;
  }
};

export default axiosInstance;
