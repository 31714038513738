import axios from "axios";
import { serverAddress } from "constants";

const axiosInstance = axios.create({
  baseURL: serverAddress,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.data) {
      throw new Error(error.response.data.message || "Request failed");
    }
    throw error;
  }
);
const token = localStorage.getItem("token");

const config = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
});



export const createHolidays = async (holidays) => {
    try {
      const body = { holidays };
  
      const response = await axiosInstance.post("/holiday/create", body, config(token));
  
      if (response.status === 200 && !response.data.error) {
        return response.data;
      } else {
        throw new Error(response.data.msg || "Failed to create holidays");
      }
    } catch (error) {
      throw error;
    }
  };

  export const getHolidays = async (year, month, page = 1, limit = 10) => {
    try {
      // Construct the query parameters with pagination
      const queryParams = `year=${year}&month=${month}&page=${page}&limit=${limit}`;
    
      // Make the GET request
      const response = await axiosInstance.get(`/holiday/get?${queryParams}`, config(token));
    
      // Check for successful response
      if (response.status === 200 && !response.data.error) {
        return response.data;
      } else {
        throw new Error(response.data.msg || "Failed to fetch holidays");
      }
    } catch (error) {
      throw error;
    }
  };
  
  
  

export default axiosInstance;
