import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

const UpdateContactModal = ({
  show,
  handleClose,
  formData,
  handleFormChange,
  handleSubmit,
}) => {
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!show) {
      setErrors({});
    }
  }, [show]);

  const validateField = (name, value) => {
    let error = "";

    if (name === "first_name" && !value) {
      error = "First Name is required";
    } else if (name === "last_name" && !value) {
      error = "Last Name is required";
    } else if (name === "email_address") {
      if (!value) {
        error = "Email Address is required";
      } else if (!/\S+@\S+\.\S+/.test(value)) {
        error = "Email Address is invalid";
      }
    } else if (name === "phone_number") {
      if (!value) {
        error = "Phone Number is required";
      } else if (!/^\d{10}$/.test(value)) {
        error = "Phone Number must be exactly 10 digits";
      }
    } else if (name === "alternate_phone_number" && value && !/^\d{10}$/.test(value)) {
      error = "Alternate Phone Number must be exactly 10 digits";
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    handleFormChange(e);
    validateField(name, value);
  };

  const validateForm = () => {
    const validationErrors = {};

    if (!formData.first_name) {
      validationErrors.first_name = "First Name is required";
    }
    if (!formData.last_name) {
      validationErrors.last_name = "Last Name is required";
    }
    if (!formData.email_address) {
      validationErrors.email_address = "Email Address is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email_address)) {
      validationErrors.email_address = "Email Address is invalid";
    }
    if (!formData.phone_number) {
      validationErrors.phone_number = "Phone Number is required";
    } else if (!/^\d{10}$/.test(formData.phone_number)) {
      validationErrors.phone_number = "Phone Number must be exactly 10 digits";
    }
    if (formData.alternate_phone_number && !/^\d{10}$/.test(formData.alternate_phone_number)) {
      validationErrors.alternate_phone_number = "Alternate Phone Number must be exactly 10 digits";
    }

    setErrors(validationErrors);

    return Object.keys(validationErrors).length === 0;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      handleSubmit();
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {formData.id ? "Update" : "Add"} Contact Information
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleFormSubmit}>
          <div className="mb-3">
            <label htmlFor="first_name" className="form-label">
              First Name
            </label>
            <input
              type="text"
              className="form-control"
              id="first_name"
              name="first_name"
              value={formData.first_name}
              onChange={handleInputChange}
            />
            {errors.first_name && (
              <div className="text-danger">{errors.first_name}</div>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="last_name" className="form-label">
              Last Name
            </label>
            <input
              type="text"
              className="form-control"
              id="last_name"
              name="last_name"
              value={formData.last_name}
              onChange={handleInputChange}
            />
            {errors.last_name && (
              <div className="text-danger">{errors.last_name}</div>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="email_address" className="form-label">
              Email Address
            </label>
            <input
              type="email"
              className="form-control"
              id="email_address"
              name="email_address"
              value={formData.email_address}
              onChange={handleInputChange}
            />
            {errors.email_address && (
              <div className="text-danger">{errors.email_address}</div>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="phone_number" className="form-label">
              Phone Number
            </label>
            <input
              type="tel"
              className="form-control"
              id="phone_number"
              name="phone_number"
              value={formData.phone_number}
              onChange={handleInputChange}
            />
            {errors.phone_number && (
              <div className="text-danger">{errors.phone_number}</div>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="alternate_phone_number" className="form-label">
              Alternate Phone Number
            </label>
            <input
              type="tel"
              className="form-control"
              id="alternate_phone_number"
              name="alternate_phone_number"
              value={formData.alternate_phone_number}
              onChange={handleInputChange}
            />
            {errors.alternate_phone_number && (
              <div className="text-danger">{errors.alternate_phone_number}</div>
            )}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleFormSubmit}
          disabled={
            !formData.first_name ||
            !formData.last_name ||
            !formData.email_address ||
            !formData.phone_number ||
            Object.values(errors).some((error) => error)
          }
        >
          {formData.id ? "Update" : "Add"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateContactModal;
