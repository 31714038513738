import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const ViewPurchaseOrder = lazy(() =>
  import("./PurchaseOrder/ViewPurchaseOrder")
);
const CreatePurchaseOrder = lazy(() =>
  import("./PurchaseOrderCreate/CreatePurchaseOrder")
);
const PurchaseRevision = lazy(() =>
  import("./PurchaseRevision/PurchaseRevision")
);
const CreateNewVendor = lazy(() => import("./vendor/CreateNewVendor"));
const VendorList = lazy(() => import("./vendor/VendorList"));
const VendorInformation = lazy(() => import("./vendor/VendorInformation"));
const purchaseroutes = [
  {
    path: "/view/po",
    element: <ViewPurchaseOrder />,
    auth: [
      ...authRoles.admin,
      ...authRoles.purchase,
      ...authRoles.storepurchase,
    ],
  },
  {
    path: "/create/po",
    element: <CreatePurchaseOrder />,
    auth: [
      ...authRoles.admin,
      ...authRoles.purchase,
      ...authRoles.storepurchase,
    ],
  },
  {
    path: "/revision/information/:uuid",
    element: <PurchaseRevision />,
    auth: [
      ...authRoles.admin,
      ...authRoles.purchase,
      ...authRoles.storepurchase,
    ],
  },
  {
    path: "/create/vendor",
    element: <CreateNewVendor />,
    auth: [
      ...authRoles.admin,
      ...authRoles.purchase,
      ...authRoles.storepurchase,
    ],
  },
  {
    path: "/view/vendor",
    element: <VendorList />,
    auth: [
      ...authRoles.admin,
      ...authRoles.purchase,
      ...authRoles.storepurchase,
    ],
  },
  {
    path: "/vendor/information/:uuid",
    element: <VendorInformation />,
    auth: [
      ...authRoles.admin,
      ...authRoles.purchase,
      ...authRoles.storepurchase,
    ],
  },
];

export default purchaseroutes;
