import React, { useEffect } from "react";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import GullLayout from "app/GullLayout/GullLayout";
import { flat } from "@utils";
import routes from "app/routes";

const getUserRoleAuthStatus = (pathname, userRoles, routes) => {
  if (!userRoles) {
    return false;
  }

  const matchedRoute = routes.find((r) => r.path === pathname);

  // Check if the route has an auth property and validate user roles
  if (matchedRoute?.auth) {
    return matchedRoute.auth.some((requiredRole) =>
      userRoles.some((userRole) => userRole.role_name === requiredRole)
    );
  }

  // If no auth is defined, allow access by default
  return true;
};

const AuthGuard = () => {
  const { pathname } = useLocation();

  // Retrieve user and token from localStorage
  const storedUser = JSON.parse(localStorage.getItem("user") || "{}");
  const isAuthenticated = Boolean(storedUser?.token);
  const userRoles = storedUser?.user_roles || [];

  // Flatten the routes
  const routesFlat = flat(routes.routes);

  // Check user role authentication
  const isUserRoleAuthenticated = getUserRoleAuthStatus(
    pathname,
    userRoles,
    routesFlat
  );

  const authenticated = isAuthenticated && isUserRoleAuthenticated;

  // Redirect if not authenticated or unauthorized
  useEffect(() => {
    if (!authenticated && pathname !== "/unauthorized") {
      <Navigate to="/sessions/unauthorized" />;
    }
  }, [authenticated, pathname]);

  return authenticated ? (
    <GullLayout>
      <Outlet />
    </GullLayout>
  ) : (
    <Navigate to={isAuthenticated ? "/sessions/unauthorized" : "/sessions/signin"} />
  );
};

export default AuthGuard;
