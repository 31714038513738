import React from 'react';
import { Modal, Button } from 'react-bootstrap';

export const DeleteContactModal = ({ show, handleClose, handleDelete, handleDeleteConfirmationChange }) => {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Contact</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this contact?</p>
          <p>Type <strong>'delete'</strong> below to confirm:</p>
          <input
            type="text"
            className="form-control"
            placeholder="Type 'delete' here"
            onChange={(e) => handleDeleteConfirmationChange(e)} // Use the passed prop here
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  