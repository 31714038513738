import axios from "axios";
import { serverAddress } from "constants";

const axiosInstance = axios.create({
  baseURL: serverAddress,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.data) {
      throw new Error(error.response.data.message || 'Request failed');
    }
    throw error;
  }
);

const config = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
});

export const getSingleClientInformation = async (uuid, token) => {
  try {
    const response = await axiosInstance.get(`/client/client-information/${uuid}`, config(token));
    return response.data.client_details;
  } catch (error) {
    throw new Error('Error fetching client information');
  }
};

export const getLeadData = async (uuid, token) => {
  try {
    const response = await axiosInstance.get(`/lead/client/${uuid}?page=1&limit=5&filterStatus=`, config(token));
    return response.data;
  } catch (error) {
    throw new Error('Error fetching lead data');
  }
};

export const updateClientInformation = async (data, token) => {
  try {
    const response = await axiosInstance.put('/client/update', data, config(token));
    return response.data;
  } catch (error) {
    throw new Error('Error updating client information');
  }
};

export const updateClientContact = async (data, token) => {
  try {
    const response = await axiosInstance.put('/client/contact/update', data, config(token));
    return response.data;
  } catch (error) {
    throw new Error('Error updating client contact information');
  }
};

export const addClientContact = async (formData, token) => {
  try {
    const response = await axiosInstance.post(`/client/add/contact`, formData, config(token));
    return response.data;
  } catch (error) {
    throw new Error('Error adding client contact information');
  }
};

export const deleteClientContact = async (formData, token) => {
  try {
    const response = await axiosInstance.delete(`/client/contact/${formData.id}`, config(token));
    return response.data;
  } catch (error) {
    throw new Error('Error deleting client contact information');
  }
};


export const deleteClient = async (clientId, token) => {
  try {
    const response = await axiosInstance.delete(`/client/${clientId}`, config(token));
    return response.data;
  } catch (error) {
    throw new Error('Error deleting client');
  }
};


export const getClientList = async (token, filterType, filterCategory,page=0,limit=0,search="") => {
  try {
    const response = await axiosInstance.get(`/client/list`, {
      ...config(token),
      params: {
        page,
        limit,
        filterType,
        filterCategory,
        search
      }
    });

    
    return response.data;
  } catch (error) {
    throw new Error('Error fetching client list');
  }
};

export const exportClientDoc = async (selectedFields, token) => {
  const selectedFieldsString = selectedFields.join(",");
  const exportUrl = `/client/export/doc?folderName=client&selectedFields=${selectedFieldsString}`;

  try {
    const response = await axiosInstance.post(exportUrl, null, config(token));
    return response.data;
  } catch (error) {
    throw new Error('Error exporting document');
  }
};



export default axiosInstance;
