import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const QuotationList = lazy(() => import("./QuotationList"));
const CreateQuotation = lazy(() => import("./QuotationCreate/CreateQuatation"));
const RevisionQuotation = lazy(() => import("./QuotationRevision/RevisionQuotation"));

const quotatationroutes = [
  {
    path: "/quotation/list",
    element: <QuotationList />,
    auth: [...authRoles.admin, ...authRoles.sales]

  },
  {
    path: "/quotation/create",
    element: <CreateQuotation />,
    auth: [...authRoles.admin, ...authRoles.sales]

  },
 
  {
    path:"/quotation/create",
    elelment:<CreateQuotation/>,
    auth: [...authRoles.admin, ...authRoles.sales]

  },
 {
  path:"/quotation/information/:uuid",
  element:<RevisionQuotation/>,
  auth: [...authRoles.admin, ...authRoles.sales]

 },
  
  
];

export default quotatationroutes;
