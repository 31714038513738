import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const MrnList = lazy(() => import("./mrn/MrnList/Mrnlist"));
const MrnCreate = lazy(() => import("./mrn/MrnCreate/CreateNewMrn"));
const MrnEdit = lazy(()=> import("./mrn/MrnEdit/MrnEdit"))
const AddItem = lazy(()=>import("./inventory/AddItem"))
const AddStock = lazy(()=>import("./inventory/AddStock"))
const ViewStock = lazy(()=>import("./inventory/ViewStock"))
const AssignStock = lazy(()=>import("./inventory/AssignStock"))
const ItemInformation = lazy(()=>import("./inventory/Item/ItemInformation"))
const storeroutes = [
  {
    path: "/mrn/list",
    element: <MrnList />,
    auth: [...authRoles.admin, ...authRoles.store, ...authRoles.storepurchase],

  },
  {
    path: "/mrn/create",
    element: <MrnCreate />,
    auth: [...authRoles.admin, ...authRoles.store, ...authRoles.storepurchase],

  },
  {
    path: "/mrn/information/:id",
    element: <MrnEdit />,
    auth: [...authRoles.admin, ...authRoles.store, ...authRoles.storepurchase],

  },
  // inventory routes
  {
    path: "/add/item",
    element: <AddItem />,
    auth: [...authRoles.admin, ...authRoles.store, ...authRoles.storepurchase],

  },
  {
    path: "/add/stock",
    element: <AddStock />,
    auth: [...authRoles.admin, ...authRoles.store, ...authRoles.storepurchase],

  }

,
  {
    path: "/view/stock",
    element: <ViewStock />,
    auth: [...authRoles.admin, ...authRoles.store, ...authRoles.storepurchase],

  }
  ,
  {
    path: "/assign/stock",
    element: <AssignStock />,
    auth: [...authRoles.admin, ...authRoles.store, ...authRoles.storepurchase],

  },
  {
    path: "/item/information/:id",
    element: <ItemInformation />,
    auth: [...authRoles.admin, ...authRoles.store, ...authRoles.storepurchase],

  }
  
  
  
  
  
];

export default storeroutes;
