import React from "react";
import { Link } from "react-router-dom";

const Unauthorized = () => {
  return (
    <div className="not-found-wrap text-center">
      <h1 className="text-60">403</h1>
      <p className="text-36 subheading mb-3">Unauthorized!</p>
      <p className="mb-5 text-muted text-18">
        You do not have permission to access this page.
      </p>
      <Link to="/" className="btn btn-lg btn-primary btn-rounded">
        Go back to home
      </Link>
    </div>
  );
};

export default Unauthorized;
