import { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import swal from "sweetalert2";
import {
  uploadDocument,
  updateDocument,
  viewDocument,
  deleteDocument,
} from "app/API/EmployeeAPI"; // Import your API functions

function EmployeeDocumentSection({
  documentTypes,
  employeeDetails,
  getEmployeeData,
}) {
  const [showModal, setShowModal] = useState(false);
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [documentToUpdate, setDocumentToUpdate] = useState(null);
  const StoredToken = localStorage.getItem("token");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const [deleteConfirmationText, setDeleteConfirmationText] = useState("");

  const handleShowDeleteModal = (document) => {
    setDocumentToDelete(document);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setDocumentToDelete(null);
    setDeleteConfirmationText("");
  };

  const handleInputChange = (e) => {
    setDeleteConfirmationText(e.target.value);
  };

  const handleDeleteClick = async () => {
    if (deleteConfirmationText === "delete" && documentToDelete) {
      try {
        const response = await deleteDocument(
          employeeDetails.id,
          documentToDelete.document_type.id,
          StoredToken
        );

        if (response.error) {
          swal.fire({
            icon: "error",
            title: "Oops!",
            text:
              response.msg ||
              "Something went wrong while deleting the document.",
          });
        } else {
          swal.fire({
            icon: "success",
            title: "Success!",
            text: response.msg || "Document deleted successfully.",
          });

          // Refresh employee data after successful deletion
          getEmployeeData();
        }

        handleCloseDeleteModal();
      } catch (error) {
        swal.fire({
          icon: "error",
          title: "Error",
          text: "An error occurred while processing your request.",
        });
        console.error(error);
      }
    } else {
      swal.fire({
        icon: "warning",
        title: "Invalid Confirmation",
        text: 'Please type "delete" to confirm the deletion.',
      });
    }
  };

  const handleShowModal = (type) => {
    setSelectedDocumentType(type);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedDocumentType(null);
    setSelectedFile(null);
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleFileSubmit = async (e) => {
    e.preventDefault();
    if (selectedFile) {
      try {
        let response;
        if (documentToUpdate) {
          // Update the document if documentToUpdate is set
          response = await updateDocument(
            selectedFile,
            employeeDetails.id,
            selectedDocumentType.id,
            documentToUpdate.id,
            StoredToken
          );
        } else {
          // Upload a new document
          response = await uploadDocument(
            selectedFile,
            employeeDetails.id,
            selectedDocumentType.id,
            StoredToken
          );
        }

        if (response.error) {
          swal.fire({
            icon: "error",
            title: "Oops!",
            text:
              response.msg ||
              "Something went wrong while uploading/updating the document.",
          });
        } else {
          swal.fire({
            icon: "success",
            title: "Success!",
            text: response.msg || "Document uploaded/updated successfully.",
          });

          // Refresh employee data after successful upload/update
          getEmployeeData();
        }

        handleCloseModal();
      } catch (error) {
        swal.fire({
          icon: "error",
          title: "Error",
          text: "An error occurred while processing your request.",
        });
        console.error(error);
      }
    }
  };
  const handleViewDocument = async (documentId) => {
    try {
      const response = await viewDocument(documentId, StoredToken); // Use documentId

      if (response.error) {
        swal.fire({
          icon: "error",
          title: "Oops!",
          text:
            response.msg || "Something went wrong while fetching the document.",
        });
      } else {
        window.open(response.download_link, "_blank");
      }
    } catch (error) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while processing your request.",
      });
      console.error(error);
    }
  };

  return (
    <>
      {employeeDetails ? (
        <div>
          {documentTypes?.map((type) => {
            const submittedDocument = employeeDetails.documents?.find(
              (doc) => doc.document_type.id === type.id
            );

            return (
              <div
                key={type.id}
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: "8px",
                  padding: "16px",
                  display: "flex",
                  alignItems: "center",
                  margin: "16px 0",
                }}
              >
                {/* Circular Status Icon */}
                <div
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    backgroundColor: submittedDocument ? "green" : "purple",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "16px",
                    cursor: submittedDocument ? "not-allowed" : "pointer", // Change cursor style when disabled
                    pointerEvents: submittedDocument ? "none" : "auto", // Disable interactions when true
                  }}
                  onClick={
                    !submittedDocument ? () => handleShowModal(type) : undefined
                  } // Disable onClick when true
                >
                  <span
                    style={{
                      color: "white",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {submittedDocument ? "✓" : "+"}
                  </span>
                </div>

                {/* Document Details */}
                <div style={{ flex: 1 }}>
                  <p
                    style={{
                      margin: "0",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    Upload {type.document_name}
                  </p>
                  {submittedDocument ? (
                    <p
                      style={{
                        margin: "0",
                        fontSize: "14px",
                        color: "#8c8c8c",
                      }}
                    >
                      Document submitted
                    </p>
                  ) : (
                    <p
                      style={{
                        margin: "0",
                        fontSize: "14px",
                        color: "#8c8c8c",
                      }}
                    >
                      Please upload your file
                    </p>
                  )}
                </div>

                {/* Upload Button */}
                {submittedDocument ? (
                  <div className="d-flex align-items-center">
                    <Button
                      variant="link"
                      onClick={() => {
                        setDocumentToUpdate(submittedDocument); // Set the document for update
                        handleShowModal(type);
                      }}
                      className="p-0 me-2"
                    >
                      <i className="fa fa-pencil text-success" />{" "}
                      {/* Green color for pencil */}
                    </Button>

                    <Button
                      variant="link"
                      onClick={() => handleShowDeleteModal(submittedDocument)} // Pass the document to delete
                      className="p-0 me-2"
                    >
                      <i className="fa fa-trash text-danger" />{" "}
                      {/* Red color for trash */}
                    </Button>

                    <Button
                      variant="link"
                      onClick={() => handleViewDocument(submittedDocument.id)} // Pass the document ID
                      className="p-0"
                    >
                      <i className="fa fa-eye" /> {/* Default color for eye */}
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <p>Loading documents...</p>
      )}

      {/* Modal for File Upload */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            Upload {selectedDocumentType?.document_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFileSubmit}>
            <Form.Group controlId="fileUpload">
              <Form.Label>Choose File</Form.Label>
              <Form.Control
                type="file"
                accept=".pdf,.doc,.docx,.png,.jpg"
                onChange={handleFileChange}
              />
            </Form.Group>
            {selectedFile && (
              <div className="mt-3">
                <strong>Selected File:</strong> {selectedFile.name}
              </div>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleFileSubmit}
            disabled={!selectedFile}
          >
            Upload
          </Button>
        </Modal.Footer>
      </Modal>

      {/* modal for doc delete */}

      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this document?</p>
          <p>Type "delete" in the input below to confirm:</p>
          <input
            type="text"
            onChange={handleInputChange}
            value={deleteConfirmationText}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Close
          </Button>
          <Button
            variant="danger"
            onClick={handleDeleteClick}
            disabled={deleteConfirmationText !== "delete"}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EmployeeDocumentSection;
