import React, { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import { Form, Button } from "react-bootstrap";
import { Breadcrumb } from "@gull";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { createEmployee, fetchRoles } from "app/API/EmployeeAPI"; // Assuming you have an API function for adding employees
import { serverAddress } from "constants";
import swal from "sweetalert2";
import { values } from "lodash";
import Select from "react-select";
import currencyCodes from "shared/data/currencyCodes";

const employeeFormSchema = yup.object().shape({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  phone_number: yup.string().required("Phone number is required"),
  email_address: yup
    .string()
    .email("Invalid email address")
    .required("Email address is required"),
  designation: yup.string().required("Designation is required"),
  department: yup.string().required("Department is required"),
  address: yup.string().required("Address is required"),
  password: yup.string().required("Password is required"),
  user_name: yup.string().required("Username is required"),
  job_title: yup.string().required("Job Title is required"),
  status: yup.string().required("Status is required"),
});

const EmployeeCreate = () => {
  const storedToken = localStorage.getItem("token");
  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email_address: "",
    designation: "",
    department: "",
    address: "",
    password: "",
    user_name: "",
    job_title: "",
    base_salary: "",
    hra: "",
    allowances: "",
    gross_salary: "",
    deductions: "",
    net_salary: "",
    bonus: "",
    salary_master_id: "",
    work_start_time: "",
    work_end_time: "",
    shift_type: "",
    status: "",
    currency: "INR",
    role_ids: [],
    token: storedToken || "",
  });
  const [salaryMaster, setSalaryMaster] = useState([]);
  const [roles, setRoles] = useState([]); // Store roles

  useEffect(() => {
    const getSalaryMaster = async () => {
      try {
        const response = await axios.get(
          `${serverAddress}/salarymaster/getAll`,
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setSalaryMaster(response.data.salary_masters);
      } catch (error) {
        console.error("Error fetching salary master data:", error);
      }
    };

    const fetchRolesData = async () => {
      try {
        const rolesData = await fetchRoles(state.token); // Use the new fetchRoles function
        setRoles(rolesData);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    getSalaryMaster();
    fetchRolesData();
  }, [state.token]);

  console.log("show salary master:", salaryMaster);

  const handleJobTitleChange = (e) => {
    const selectedJobTitle = e.target.value;

    // Find the job title details that match the selected job title
    const jobDetails = salaryMaster.find(
      (job) => job.job_title === selectedJobTitle
    );

    if (jobDetails) {
      // If a matching job title is found, update the state with the corresponding salary details
      const {
        base_salary,
        hra,
        allowances,
        gross_salary,
        deductions,
        net_salary,
        bonus,
        work_start_time,
        work_end_time,
        shift_type,
        id,
      } = jobDetails;

      // Format the time values
      const formatTime = (timeString) => {
        const [hours, minutes] = timeString.split(":");
        return `${hours.padStart(2, "0")}:${minutes.padStart(2, "0")}`;
      };

      setState((prevState) => ({
        ...prevState,
        job_title: selectedJobTitle,
        base_salary,
        hra,
        allowances,
        gross_salary,
        deductions,
        net_salary,
        bonus,
        work_start_time: formatTime(work_start_time),
        work_end_time: formatTime(work_end_time),
        shift_type,
        salary_master_id: id,
      }));
    } else {
      // If no matching job title is found, clear the salary fields
      setState((prevState) => ({
        ...prevState,
        job_title: selectedJobTitle,
        base_salary: "",
        hra: "",
        allowances: "",
        gross_salary: "",
        deductions: "",
        net_salary: "",
        bonus: "",
        salary_master_id: "",
        work_start_time: "",
        work_end_time: "",
        shift_type: "",
      }));
    }
  };

  const handleRoleChange = (selectedRoles) => {
    const roleIds = selectedRoles.map((role) => role.value);
    setState((prevState) => ({
      ...prevState,
      roles: roleIds,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      // Transform selected roles to include permissions
      const rolePermissions = state.roles.map((roleId) => ({
        role_id: roleId,
        can_create: true,
        can_read: true,
        can_update: true,
        can_delete: true,
      }));

      // Format the time values
      const formatTime = (timeString) => {
        if (!timeString) return "";
        const [hours, minutes] = timeString.split(":");
        return `${hours.padStart(2, "0")}:${minutes.padStart(2, "0")}:00`;
      };

      const payload = {
        first_name: values.first_name,
        last_name: values.last_name,
        phone_number: values.phone_number,
        email_address: values.email_address,
        designation: values.designation,
        department: values.department,
        address: values.address,
        password: values.password,
        user_name: values.user_name,
        job_title: values.job_title,
        base_salary: state.base_salary,
        hra: state.hra,
        allowances: state.allowances,
        gross_salary: state.gross_salary,
        deductions: state.deductions,
        net_salary: state.net_salary,
        bonus: state.bonus,
        salary_master_id: state.salary_master_id,
        status: values.status,
        role_ids: rolePermissions,
        token: state.token,
        currency: state.currency,
        work_start_time: formatTime(state.work_start_time),
        work_end_time: formatTime(state.work_end_time),
        shift_type: state.shift_type,
      };

      const response = await createEmployee(payload, payload.token);

      console.log("show response:", response);
      swal.fire({
        title: "Added!",
        text: "Employee Added Successfully.",
        type: "success",
        icon: "success",
        timer: 1500,
      });
      resetForm();
      setState({
        first_name: "",
        last_name: "",
        phone_number: "",
        email_address: "",
        designation: "",
        department: "",
        address: "",
        password: "",
        user_name: "",
        job_title: "",
        base_salary: "",
        hra: "",
        allowances: "",
        gross_salary: "",
        deductions: "",
        net_salary: "",
        bonus: "",
        salary_master_id: "",
        work_start_time: "",
        work_end_time: "",
        shift_type: "",
        status: "",
        role_ids: [],
        token: storedToken || "",
      });
    } catch (error) {
      swal.fire({
        title: "Error!",
        text: "Failed to add employee.Try with different username or contact support",
        type: "error",
        icon: "error",
        timer: 1500,
      });
    }

    setSubmitting(false);
  };

  const roleOptions = roles.map((role) => ({
    value: role.id,
    label: role.role_name,
  }));

  return (
    <div>
      <Breadcrumb routeSegments={[{ name: "Add Employee" }]} />
      <div className="row">
        <div className="col-md-12">
          <div className="card mb-4">
            <div className="card-body">
              <Formik
                initialValues={state}
                validationSchema={employeeFormSchema}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="d-flex justify-content-end form-group align-items-center mb-2">
                        {/* <Button
                          type="reset"
                          variant="secondary"
                          onClick={() =>
                            setState({
                              first_name: "",
                              last_name: "",
                              phone_number: "",
                              email_address: "",
                              designation: "",
                              department: "",
                              address: "",
                              password: "",
                              user_name: "",
                              job_title: "",
                              base_salary: "",
                              hra: "",
                              allowances: "",
                              gross_salary: "",
                              deductions: "",
                              net_salary: "",
                              bonus: "",
                              salary_master_id: "",
                              work_start_time: "",
                              work_end_time: "",
                              shift_type: "",
                              status: "",
                              role_ids: [],
                              token: storedToken || "",
                            })
                          }
                          style={{ width: "100px" }}
                        >
                          Reset
                        </Button> */}
                      </div>
                      <div className="col-md-6 form-group mb-3">
                        <label htmlFor="first_name">First Name</label>
                        <input
                          id="first_name"
                          name="first_name"
                          className="form-control"
                          placeholder="Enter first name"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.first_name}
                        />
                        {errors.first_name && touched.first_name && (
                          <div className="text-danger mt-1 ml-2">
                            {errors.first_name}
                          </div>
                        )}
                      </div>

                      <div className="col-md-6 form-group mb-3">
                        <label htmlFor="last_name">Last Name</label>
                        <input
                          id="last_name"
                          name="last_name"
                          className="form-control"
                          placeholder="Enter last name"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.last_name}
                        />
                        {errors.last_name && touched.last_name && (
                          <div className="text-danger mt-1 ml-2">
                            {errors.last_name}
                          </div>
                        )}
                      </div>

                      <div className="col-md-6 form-group mb-3">
                        <label htmlFor="phone_number">Phone Number</label>
                        <input
                          id="phone_number"
                          name="phone_number"
                          className="form-control"
                          placeholder="Enter phone number"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone_number}
                        />
                        {errors.phone_number && touched.phone_number && (
                          <div className="text-danger mt-1 ml-2">
                            {errors.phone_number}
                          </div>
                        )}
                      </div>

                      <div className="col-md-6 form-group mb-3">
                        <label htmlFor="email_address">Email Address</label>
                        <input
                          id="email_address"
                          name="email_address"
                          className="form-control"
                          placeholder="Enter email address"
                          type="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email_address}
                        />
                        {errors.email_address && touched.email_address && (
                          <div className="text-danger mt-1 ml-2">
                            {errors.email_address}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6 form-group mb-3">
                        <label htmlFor="roles">Roles</label>
                        <Select
                          id="roles"
                          name="roles"
                          isMulti
                          options={roleOptions}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={handleRoleChange}
                        />
                      </div>
                      <div className="col-md-6 form-group mb-3">
                        <label htmlFor="designation">Designation</label>
                        <select
                          id="designation"
                          name="designation"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.designation}
                        >
                          <option disabled value="">
                            Please select a Designation
                          </option>
                          <option value="admin">Admin</option>
                          <option value="sales">Sales</option>
                          <option value="purchase">Purchase</option>
                          <option value="orders">
                            Order Handling and Production
                          </option>
                          <option value="store">Store</option>
                          <option value="storepurchase">
                            Store & Purchase
                          </option>
                        </select>
                        {errors.designation && touched.designation && (
                          <div className="text-danger mt-1 ml-2">
                            {errors.designation}
                          </div>
                        )}
                      </div>

                      <div className="col-md-6 form-group mb-3">
                        <label htmlFor="department">Department</label>
                        <select
                          id="department"
                          name="department"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.department}
                        >
                          <option disabled value="">
                            Please select Department
                          </option>
                          <option value="admin">Admin</option>
                          <option value="sales">Sales</option>
                          <option value="purchase">Purchase</option>
                          <option value="orders">
                            Order Handling and Production
                          </option>
                          <option value="store">Store</option>
                          <option value="storePurchase">
                            Store & Purchase
                          </option>
                        </select>
                        {errors.department && touched.department && (
                          <div className="text-danger mt-1 ml-2">
                            {errors.department}
                          </div>
                        )}
                      </div>

                      <div className="col-md-6 form-group mb-3">
                        <label htmlFor="address">Address</label>
                        <input
                          id="address"
                          name="address"
                          className="form-control"
                          placeholder="Enter address"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.address}
                        />
                        {errors.address && touched.address && (
                          <div className="text-danger mt-1 ml-2">
                            {errors.address}
                          </div>
                        )}
                      </div>

                      <div className="col-md-6 form-group mb-3">
                        <label htmlFor="password">Password</label>
                        <input
                          id="password"
                          name="password"
                          className="form-control"
                          placeholder="Enter password"
                          type="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                        />
                        {errors.password && touched.password && (
                          <div className="text-danger mt-1 ml-2">
                            {errors.password}
                          </div>
                        )}
                      </div>

                      <div className="col-md-6 form-group mb-3">
                        <label htmlFor="user_name">Username</label>
                        <input
                          id="user_name"
                          name="user_name"
                          className="form-control"
                          placeholder="Enter username"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.user_name}
                        />
                        {errors.user_name && touched.user_name && (
                          <div className="text-danger mt-1 ml-2">
                            {errors.user_name}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6 form-group mb-3">
                        <label htmlFor="job_title">Job Title</label>
                        <select
                          id="job_title"
                          name="job_title"
                          className="form-control"
                          onChange={(e) => {
                            handleJobTitleChange(e); // Your existing function to update local state
                            setFieldValue("job_title", e.target.value); // Update Formik state
                          }}
                          onBlur={handleBlur}
                          value={state.job_title}
                        >
                          <option disabled value="">
                            Please select Job Title
                          </option>
                          {salaryMaster.map((item, key) => (
                            <option key={key} value={item.job_title}>
                              {item.job_title}
                            </option>
                          ))}
                        </select>
                        {errors.job_title && touched.job_title && (
                          <div className="text-danger mt-1 ml-2">
                            {errors.job_title}
                          </div>
                        )}
                      </div>
                      {state.job_title && (
                        <>
                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="status">Status</label>
                            <select
                              id="status"
                              name="status"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.status}
                            >
                              <option disabled value="">
                                Please select Status
                              </option>
                              <option value="pending">Pending</option>
                              <option value="active">Active</option>
                              <option value="inactive">Inactive</option>
                              <option value="terminated">Terminated</option>
                            </select>
                            {errors.status && touched.status && (
                              <div className="text-danger mt-1 ml-2">
                                {errors.status}
                              </div>
                            )}
                          </div>
                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="base_salary">Base Salary</label>
                            <input
                              id="base_salary"
                              name="base_salary"
                              className="form-control"
                              // placeholder="Enter username"
                              type="text"
                              onChange={handleInputChange}
                              onBlur={handleBlur}
                              value={state.base_salary}
                            />
                            {errors.base_salary && touched.base_salary && (
                              <div className="text-danger mt-1 ml-2">
                                {errors.base_salary}
                              </div>
                            )}
                          </div>
                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="hra">HRA</label>
                            <input
                              id="hra"
                              name="hra"
                              className="form-control"
                              // placeholder="Enter username"
                              type="text"
                              onChange={handleInputChange}
                              onBlur={handleBlur}
                              value={state.hra}
                            />
                            {errors.hra && touched.hra && (
                              <div className="text-danger mt-1 ml-2">
                                {errors.hra}
                              </div>
                            )}
                          </div>
                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="allowances">Allowances</label>
                            <input
                              id="allowances"
                              name="allowances"
                              className="form-control"
                              // placeholder="Enter username"
                              type="text"
                              onChange={handleInputChange}
                              onBlur={handleBlur}
                              value={state.allowances}
                            />
                            {errors.allowances && touched.allowances && (
                              <div className="text-danger mt-1 ml-2">
                                {errors.allowances}
                              </div>
                            )}
                          </div>
                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="gross_salary">Gross Salary</label>
                            <input
                              id="gross_salary"
                              name="gross_salary"
                              className="form-control"
                              // placeholder="Enter username"
                              type="text"
                              onChange={handleInputChange}
                              onBlur={handleBlur}
                              value={state.gross_salary}
                            />
                            {errors.gross_salary && touched.gross_salary && (
                              <div className="text-danger mt-1 ml-2">
                                {errors.gross_salary}
                              </div>
                            )}
                          </div>
                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="deductions">Deductions</label>
                            <input
                              id="deductions"
                              name="deductions"
                              className="form-control"
                              // placeholder="Enter username"
                              type="text"
                              onChange={handleInputChange}
                              onBlur={handleBlur}
                              value={state.deductions}
                            />
                            {errors.deductions && touched.deductions && (
                              <div className="text-danger mt-1 ml-2">
                                {errors.deductions}
                              </div>
                            )}
                          </div>
                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="net_salary">Net Salary</label>
                            <input
                              id="net_salary"
                              name="net_salary"
                              className="form-control"
                              // placeholder="Enter username"
                              type="text"
                              onChange={handleInputChange}
                              onBlur={handleBlur}
                              value={state.net_salary}
                            />
                            {errors.net_salary && touched.net_salary && (
                              <div className="text-danger mt-1 ml-2">
                                {errors.net_salary}
                              </div>
                            )}
                          </div>
                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="bonus">Bonus</label>
                            <input
                              id="bonus"
                              name="bonus"
                              className="form-control"
                              // placeholder="Enter username"
                              type="text"
                              onChange={handleInputChange}
                              onBlur={handleBlur}
                              value={state.bonus}
                            />
                            {errors.bonus && touched.bonus && (
                              <div className="text-danger mt-1 ml-2">
                                {errors.bonus}
                              </div>
                            )}
                          </div>
                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="work_start_time">
                              Work Start Time
                            </label>
                            <input
                              id="work_start_time"
                              name="work_start_time"
                              className="form-control"
                              type="time"
                              onChange={handleInputChange}
                              onBlur={handleBlur}
                              value={state.work_start_time}
                            />
                            {errors.work_start_time &&
                              touched.work_start_time && (
                                <div className="text-danger mt-1 ml-2">
                                  {errors.work_start_time}
                                </div>
                              )}
                          </div>
                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="work_end_time">Work End Time</label>
                            <input
                              id="work_end_time"
                              name="work_end_time"
                              className="form-control"
                              type="time"
                              onChange={handleInputChange}
                              onBlur={handleBlur}
                              value={state.work_end_time}
                            />
                            {errors.work_end_time && touched.work_end_time && (
                              <div className="text-danger mt-1 ml-2">
                                {errors.work_end_time}
                              </div>
                            )}
                          </div>
                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="shift_type">Shift Type</label>
                            <select
                              className="form-control"
                              id="shift_type"
                              name="shift_type"
                              value={state.shift_type}
                              onChange={(e) => {
                                handleInputChange(e);
                                setFieldValue("shift_type", e.target.value);
                              }}
                              onBlur={handleBlur}
                            >
                              <option value="" disabled>
                                --Select Shift--
                              </option>
                              <option value="morning">Morning</option>
                              <option value="evening">Evening</option>
                              <option value="night">Night</option>
                            </select>
                            {errors.shift_type && touched.shift_type && (
                              <div className="text-danger mt-1 ml-2">
                                {errors.shift_type}
                              </div>
                            )}
                          </div>
                          <div className="col-md-6 form-group mb-3">
                            <label htmlFor="shift_type">Currency</label>
                            <select
                              className="form-control"
                              id="currency"
                              name="currency"
                              value={state.currency}
                              onChange={(e) => {
                                handleInputChange(e);
                                setFieldValue("currency", e.target.value);
                              }}
                              onBlur={handleBlur}
                            >
                              <option value="">Select Currency</option>
                              {currencyCodes?.map((currency, index) => (
                                <option
                                  key={`${currency.AlphabeticCode}-${index}`}
                                  value={currency.AlphabeticCode}
                                >
                                  {currency.Currency} ({currency.AlphabeticCode}
                                  )
                                </option>
                              ))}
                            </select>
                            {errors.shift_type && touched.shift_type && (
                              <div className="text-danger mt-1 ml-2">
                                {errors.shift_type}
                              </div>
                            )}
                          </div>
                        </>
                      )}

                      <div className="form-group">
                        <Button
                          className="mt-2"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeCreate;
