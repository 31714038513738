import React from "react";
import { Modal, Button, Form } from "react-bootstrap";

const UpdateRemarksModal = ({ show, handleClose, remarks, handleChange, handleUpdate }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update Remarks</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="remarksTextarea">
          <Form.Label>Remarks:</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={remarks}
            onChange={handleChange}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleUpdate}>
          Update Remarks
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateRemarksModal;
