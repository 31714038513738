import axios from "axios";
import { serverAddress } from "constants";

const axiosInstance = axios.create({
  baseURL: serverAddress,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.data) {
      throw new Error(error.response.data.message || "Request failed");
    }
    throw error;
  }
);
const token = localStorage.getItem("token");

const config = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
});

// Fetch MRN list API
export const fetchAllAttendence = async (page = 0, limit = 0, startDate = "",endDate="",status="",  employeeId = ""
) => {
    try {
      const params = {
        page: page,
        limit: limit,
        startDate: startDate,
        endDate: endDate,
        status: status,
        employeeId:employeeId
      };
      const response = await axiosInstance.get("/attendence/get/all", {
        ...config(token),
        params,
      });
  
      if (response.status === 200 && !response.data.error) {
        return response.data;
      } else {
        throw new Error("Error fetching MRN list");
      }
    } catch (error) {
      throw error;
    }
  };

 export const uploadAttendanceRecords = async (file) => {
  const token = localStorage.getItem("token");
  const formData = new FormData();
  formData.append('file', file);

  const response = await axiosInstance.post("/attendence/upload/attendance", formData, {
    ...config(token),
    headers: {
      ...config(token).headers,
      'Content-Type': 'multipart/form-data',
    },
  });

  // Return the response data regardless of the error flag
  return response.data;
};



export default axiosInstance;
