import axios from "axios";
import { serverAddress } from "constants";

const axiosInstance = axios.create({
  baseURL: serverAddress,
});

export const getLeaveRequests = async (
  token,
  filterStatus = "",
  dateRange = null
) => {
  try {
    const body = {
      status: filterStatus,
      search: "",
      ...(dateRange && {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      }),
    };

    const response = await axiosInstance.post("/leave/get/all", body, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.status === 200 && !response.data.error) {
      return response.data;
    } else {
      throw new Error("Error fetching leave requests");
    }
  } catch (error) {
    throw error;
  }
};

// Update leave status
export const updateLeaveStatus = async (token, leaveId, payload) => {
  try {
    const response = await axiosInstance.put(
      `/leave/update/${leaveId}`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (response.status === 200 && !response.data.error) {
      return response.data;
    } else {
      throw new Error("Error updating leave status");
    }
  } catch (error) {
    throw error;
  }
};

export const allocateMultipleLeaves = async (token, leaveData) => {
  try {
    const response = await axiosInstance.post(
      "/leave/allocation/multiple",
      leaveData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          ContentType: `application/json`,
        },
      }
    );

    if (response.status === 200 && !response.data.error) {
      return response.data; // Return the response if there's no error
    } else {
      throw new Error("Error allocating multiple leaves");
    }
  } catch (error) {
    throw error;
  }
};

export const createLeaveType = async (token, leaveData) => {
  try {
    const response = await axiosInstance.post(
      "/leavesmaster/create",
      leaveData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          ContentType: `application/json`,
        },
      }
    );

    if (response.status === 200 && !response.data.error) {
      return response.data; // Return the response if there's no error
    } else {
      throw new Error("Error creating leave");
    }
  } catch (error) {
    throw error;
  }
};

export const applyLeave = async (leaveData, token) => {
  try {
    const response = await axiosInstance.post("/leave/create", leaveData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
export const fetchAllLeaves = async (token) => {
  try {
    const response = await axiosInstance.get("/leavesmaster/getAll", {
      headers: {
        Authorization: `Bearer ${token}`,
        ContentType: `application/json`,
      },
    });

    if (response.status === 200 && !response.data.error) {
      return response.data; // Return the response if there's no error
    } else {
      throw new Error("Error fetching leaves data");
    }
  } catch (error) {
    throw error;
  }
};

export const getleaveRequestByEmployee = async (
  token,
  employeeId,
  page = 1,
  limit = 5
) => {
  try {
    const response = await axiosInstance.get(
      `/leave/${employeeId}?page=${page}&limit=${limit}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (response.status === 200 && !response.data.error) {
      return response.data;
    } else {
      throw new Error("Error fetching leave requests");
    }
  } catch (error) {
    throw error;
  }
};

export default axiosInstance;
