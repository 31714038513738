import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";
import ClientInformation from "./ClientInformation";

const Clientlist = lazy(() => import("./ClientList"));
const CreateClient = lazy(() => import("./CreateNewClient"));

const clientRoutes = [
  {
    path: "/client/list",
    element: <Clientlist />,
    auth: [...authRoles.admin, ...authRoles.sales]
  },
  {
    path: "/client/create",
    element: <CreateClient />,
    auth: [...authRoles.admin, ...authRoles.sales]
  },
  {
    path: "/client/information/:uuid",
    element: <ClientInformation />,
    auth: [...authRoles.admin, ...authRoles.sales]
  },
];

export default clientRoutes;
