import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md"; // Cross icon
import plusIcon from "shared/icons/plus.png"; // Replace with your actual path
import swal from "sweetalert2";
import { createHolidays, getHolidays } from "app/API/MonthlyHolidayAPI";
import moment from "moment";
import { Breadcrumb, SimpleCard } from "@gull";
const AssignMonthlyHoliday = () => {
  const [holidays, setHolidays] = useState([
    { holiday_date: "", type: "holiday", description: "" },
  ]);
  const [showModal, setShowModal] = useState(false);

  const [holidayList, setHolidayList] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false); // For filter dropdown visibility
  const [filterYear, setFilterYear] = useState(moment().year());
  const [filterMonth, setFilterMonth] = useState(moment().month() + 1);

  useEffect(() => {
    fetchHolidayData(filterYear, filterMonth);
  }, [filterYear, filterMonth]);

  // Fetch holidays based on selected year and month
  const fetchHolidayData = async (year, month) => {
    try {
      const response = await getHolidays(year, month); // Fetch holidays for the selected year and month
      if (response && !response.error) {
        setHolidayList(response?.monthly_holidays);
        setTotalItems(response?.total);
      }
    } catch (error) {
      swal.fire({
        title: "Error!",
        text: error.message || "An error occurred while fetching holidays.",
        icon: "error",
        timer: 2000,
      });
    }
  };

  const resetFilters = () => {
    setFilterYear(moment().year());
    setFilterMonth(moment().month() + 1);
    fetchHolidayData(moment().year(), moment().month() + 1);
    setFilterDropdownOpen(false);
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedHolidays = [...holidays];
    updatedHolidays[index][name] = value;
    setHolidays(updatedHolidays);
  };

  const handleAddRow = () => {
    setHolidays([
      ...holidays,
      { holiday_date: "", type: "holiday", description: "" },
    ]);
  };

  const handleRemoveRow = (index) => {
    const updatedHolidays = holidays.filter((_, i) => i !== index);
    setHolidays(updatedHolidays);
  };

  const handleSubmit = async () => {
    // Validate all fields
    const incompleteHoliday = holidays.find(
      (holiday) =>
        !holiday.holiday_date || !holiday.type || !holiday.description.trim()
    );

    if (incompleteHoliday) {
      swal.fire({
        title: "Error!",
        text: "All fields (Date, Type, and Description) are mandatory. Please fill out all fields before submitting.",
        icon: "error",
        timer: 3000,
      });
      return;
    }

    try {
      const response = await createHolidays(holidays);

      if (response && !response.error) {
        const createdHolidays = response.monthly_holidays.filter(
          (item) => item.created
        );
        const existingHolidays = response.monthly_holidays.filter(
          (item) => !item.created
        );

        // Success message for created holidays
        if (createdHolidays.length > 0) {
          const createdDates = createdHolidays
            .map((item) => item.holiday.holiday_date)
            .join(", ");
          swal.fire({
            title: "Success!",
            text: `Holidays created successfully for dates: ${createdDates}`,
            icon: "success",
            timer: 2000,
          });
        }

        // Warning message for holidays that already exist
        if (existingHolidays.length > 0) {
          const existingDates = existingHolidays
            .map((item) => item.holiday.holiday_date)
            .join(", ");
          swal.fire({
            title: "Note!",
            text: `Holidays already exist for dates: ${existingDates}`,
            icon: "warning",
            timer: 3000,
          });
        }
      } else {
        throw new Error(response?.msg || "Failed to create holidays");
      }
      setShowModal(false);
      setHolidays([{ holiday_date: "", type: "holiday", description: "" }]);
    } catch (error) {
      // Error message
      swal.fire({
        title: "Error!",
        text: error.message || "An error occurred while creating holidays.",
        icon: "error",
        timer: 2000,
      });
    }
  };

  const toggleFilterDropdown = () => {
    setFilterDropdownOpen(!filterDropdownOpen);
  };

  const handleYearChange = (event) => {
    setFilterYear(parseInt(event.target.value, 10));
  };

  const handleMonthChange = (event) => {
    setFilterMonth(parseInt(event.target.value, 10));
  };

  const applyFilters = () => {
    setFilterMonth(filterYear);
    setFilterMonth(filterMonth);
    setFilterDropdownOpen(false); // Close dropdown after applying filters
  };

  const handlePageChange = (page) => setCurrentPage(page);

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const getVisiblePages = () => {
    const pageRange = 5;
    const start = Math.max(1, currentPage - Math.floor(pageRange / 2));
    const end = Math.min(totalPages, start + pageRange - 1);
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };

  return (
    <>
      <Breadcrumb routeSegments={[{ name: "Holidays List" }]} />

      <SimpleCard>
        <div className="d-flex justify-content-end align-items-center mb-3 gap-3">
          <Button onClick={() => setShowModal(true)}>Add Holidays</Button>

          <div className="dropdown">
            <button
              type="button"
              className="btn btn-primary dropdown-toggle"
              onClick={toggleFilterDropdown}
              style={{
                backgroundColor: "#BBC5EA",
                borderColor: "#BBC5EA",
                color: "#000000",
                height: "39px",
                width: "82px",
              }}
            >
              Filter&nbsp;
              <i className="fa fa-filter" aria-hidden="true"></i>
            </button>
            {filterDropdownOpen && (
              <div
                className="dropdown-menu"
                style={{
                  display: "block",
                  position: "absolute",
                  right: 0,
                  left: "auto",
                  width: "220px",
                  padding: "20px",
                  borderRadius: "10px",
                  border: "2px solid #DEE2E6",
                }}
              >
                <div>
                  <label>Year:</label>
                  <select
                    className="form-select"
                    value={filterYear}
                    onChange={handleYearChange}
                    style={{
                      width: "100%",
                      marginBottom: "10px",
                      padding: "5px",
                      borderRadius: "5px",
                      border: "1px solid #ced4da",
                    }}
                  >
                    {Array.from({ length: 10 }, (_, i) => {
                      const year = moment().year() - 5 + i;
                      return (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div style={{ marginBottom: "15px" }}>
                  <label>Month:</label>

                  <select
                    className="form-select"
                    value={filterMonth}
                    onChange={handleMonthChange}
                    style={{
                      width: "100%",
                      marginBottom: "10px",
                      padding: "5px",
                      borderRadius: "5px",
                      border: "1px solid #ced4da",
                    }}
                  >
                    {Array.from({ length: 12 }, (_, i) => (
                      <option key={i + 1} value={i + 1}>
                        {moment().month(i).format("MMMM")}
                      </option>
                    ))}
                  </select>
                </div>

                <div style={{ marginTop: "15px" }}>
                  <button
                    type="button"
                    className="btn"
                    style={{
                      color: "#0075FF",
                      borderColor: "#0075FF",
                      fontSize: "15px",
                    }}
                    onClick={resetFilters}
                  >
                    Reset Filters
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        <table className="table common-table">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Holiday Date</th>
              <th>Holiday Type</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {holidayList.length > 0 ? (
              holidayList.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item?.holiday_date}</td>
                  <td>{item?.type}</td>
                  <td>{item?.description}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">No attendance records available</td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="pagination-container">
          <div className="items-per-page">
            <label>Items per page: </label>
            <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>

          <div className="pagination-controls">
            <button
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            >
              First
            </button>
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Prev
            </button>

            {getVisiblePages().map((page) => (
              <button
                key={page}
                className={currentPage === page ? "active" : ""}
                onClick={() => handlePageChange(page)}
              >
                {page}
              </button>
            ))}

            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
            <button
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
            >
              Last
            </button>
          </div>
        </div>
      </SimpleCard>

      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Manage Holidays</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            {holidays.map((holiday, index) => (
              <div
                key={index}
                className="row align-items-center mb-3"
                style={{ display: "flex" }}
              >
                <div className="col-md-3">
                  <label htmlFor={`holiday_date_${index}`}>Date</label>
                  <input
                    type="date"
                    id={`holiday_date_${index}`}
                    name="holiday_date"
                    className="form-control"
                    value={holiday.holiday_date}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </div>

                <div className="col-md-3">
                  <label htmlFor={`type_${index}`}>Type</label>
                  <select
                    id={`type_${index}`}
                    name="type"
                    className="form-select"
                    value={holiday.type}
                    onChange={(e) => handleInputChange(index, e)}
                  >
                    <option value="holiday">Holiday</option>
                    <option value="working">Working</option>
                  </select>
                </div>

                <div className="col-md-4">
                  <label htmlFor={`description_${index}`}>Description</label>
                  <input
                    type="text"
                    id={`description_${index}`}
                    name="description"
                    className="form-control"
                    value={holiday.description}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </div>

                <div className="col-md-2 d-flex justify-content-center">
                  {index === 0 ? (
                    <img
                      src={plusIcon}
                      alt="Add Row"
                      style={{
                        width: "24px",
                        height: "24px",
                        cursor: "pointer",
                      }}
                      onClick={handleAddRow}
                    />
                  ) : (
                    <MdClose
                      size={24}
                      color="red"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleRemoveRow(index)}
                    />
                  )}
                </div>
              </div>
            ))}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AssignMonthlyHoliday;
