import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const Leaveslist = lazy(() => import("./LeavesList"));
const LeaveAllocation = lazy(() => import("./LeaveAllocation/LeaveAllocation"));

const leavesroutes = [
  {
    path: "/leave/list",
    element: <Leaveslist />,
    auth: authRoles.admin,
  },
  {
    path: "/allocate/leave",
    element: <LeaveAllocation />,
    auth: authRoles.admin,
  },
 
];

export default leavesroutes;
