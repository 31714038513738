import { fetchAllSalaryMasters, fetchRoles } from "app/API/EmployeeAPI";
import React, { useEffect, useState } from "react";
import Select from "react-select"; // Import react-select for multi-select
import currencyCodes from "shared/data/currencyCodes";

const EmployeeModal = ({
  showModal,
  handleCloseModal,
  handleSubmit,
  mode,
  employeeData,
  employeeEditData,
  setEmployeeEditData,
}) => {
  const [roles, setRoles] = useState([]); // Store roles
  const storedToken = localStorage.getItem("token");
  const [selectedRoles, setSelectedRoles] = useState([]); // Selected roles for the employee
  const [salaryMaster, setSalaryMaster] = useState([]);

  useEffect(() => {
    setEmployeeEditData({
      id: employeeData.id,
      first_name: employeeData.first_name,
      address: employeeData.address,
      last_name: employeeData.last_name,
      email_address: employeeData.email_address,
      designation: employeeData.designation,
      department: employeeData.department,
      phone_number: employeeData.phone_number,
      base_salary: employeeData?.employee_salary_mappers[0]?.base_salary,
      hra: employeeData?.employee_salary_mappers[0]?.hra,
      allowances: employeeData?.employee_salary_mappers[0]?.allowances,
      gross_salary: employeeData?.employee_salary_mappers[0]?.gross_salary,
      net_salary: employeeData?.employee_salary_mappers[0]?.net_salary,
      bonus: employeeData?.employee_salary_mappers[0]?.bonus,
      work_start_time:
        employeeData?.employee_salary_mappers[0]?.work_start_time,
      work_end_time: employeeData?.employee_salary_mappers[0]?.work_end_time,
      shift_type: employeeData?.employee_salary_mappers[0]?.shift_type,
      deductions: employeeData?.employee_salary_mappers[0]?.deductions,
      currency: employeeData?.employee_salary_mappers[0]?.currency,
      salary_master_id:
        employeeData?.employee_salary_mappers[0]?.salary_master_id,
      job_title:
        employeeData?.employee_salary_mappers[0]?.salary_master?.job_title,
      currency: employeeData?.employee_salary_mappers[0]?.currency,
      status: employeeData.status,
      username: employeeData?.login?.user_name,
    });
    setSelectedRoles(
      employeeData?.roles?.map((role) => ({
        label: role.role_name,
        value: role.role_name,
        role_id: role.id,
      })) || []
    );
  }, [employeeData]);

  useEffect(() => {
    const fetchSalaryMasters = async () => {
      try {
        const salaryMasters = await fetchAllSalaryMasters(storedToken);
        setSalaryMaster(salaryMasters);
      } catch (error) {
        console.error("Error fetching salary master data:", error);
      }
    };
    fetchSalaryMasters();

    const fetchRolesData = async () => {
      try {
        const rolesData = await fetchRoles(storedToken || "");
        const rolesOptions = rolesData.map((role) => ({
          label: role.role_name,
          value: role.role_name,
          role_id: role.id,
        }));
        setRoles(rolesOptions);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };
    fetchRolesData();
  }, [storedToken]);

  const handleJobTitleChange = (e) => {
    const selectedJobTitle = e.target.value;

    // Find the job title details that match the selected job title
    const jobDetails = salaryMaster.find(
      (job) => job.job_title === selectedJobTitle
    );

    if (jobDetails) {
      // If a matching job title is found, update the state with the corresponding salary details
      const {
        base_salary,
        hra,
        allowances,
        gross_salary,
        deductions,
        net_salary,
        bonus,
        work_start_time,
        work_end_time,
        shift_type,
        id,
      } = jobDetails;

      // Format the time values
      const formatTime = (timeString) => {
        const [hours, minutes] = timeString.split(":");
        return `${hours.padStart(2, "0")}:${minutes.padStart(2, "0")}`;
      };

      setEmployeeEditData((prevState) => ({
        ...prevState,
        job_title: selectedJobTitle,
        base_salary,
        hra,
        allowances,
        gross_salary,
        deductions,
        net_salary,
        bonus,
        work_start_time: formatTime(work_start_time),
        work_end_time: formatTime(work_end_time),
        shift_type,
        salary_master_id: id,
      }));
    } else {
      // If no matching job title is found, clear the salary fields
      setEmployeeEditData((prevState) => ({
        ...prevState,
        job_title: selectedJobTitle,
        base_salary: "",
        hra: "",
        allowances: "",
        gross_salary: "",
        deductions: "",
        net_salary: "",
        bonus: "",
        salary_master_id: "",
        work_start_time: "",
        work_end_time: "",
        shift_type: "",
      }));
    }
  };

  console.log("show salary master fast:", salaryMaster);

  const handleRoleChange = (selectedOptions) => {
    setSelectedRoles(selectedOptions);
    setEmployeeEditData((prevState) => ({
      ...prevState,
      roles: selectedOptions.map((option) => ({
        access_role: {
          can_create: true,
          can_delete: true,
          can_read: true,
          can_update: true,
        },

        role_name: option.label,
        role_id: option.role_id,
      })),
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmployeeEditData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  console.log("show employee Data:", employeeData);
  console.log("show employee edit Data:", employeeEditData);

  return (
    <div
      className={`modal fade ${showModal && "show"}`}
      style={{ display: showModal ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {mode === "update" ? "Edit Employee" : "Add Employee"}
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={handleCloseModal}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form>
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="first_name"
                        className="form-label fw-bold"
                      >
                        First Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="first_name"
                        name="first_name"
                        value={employeeEditData.first_name}
                        onChange={(e) => handleInputChange(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="last_name" className="form-label fw-bold">
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="last_name"
                        name="last_name"
                        value={employeeEditData.last_name}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="email_address"
                        className="form-label fw-bold"
                      >
                        Email Address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email_address"
                        name="email_address"
                        value={employeeEditData.email_address}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="designation"
                        className="form-label fw-bold"
                      >
                        Designation
                      </label>
                      {/* <input
                        type="text"
                        className="form-control"
                        id="designation"
                        name="designation"
                        value={employeeEditData.designation}
                        onChange={handleInputChange}
                      /> */}

                      <select
                        id="designation"
                        name="designation"
                        className="form-control"
                        onChange={handleInputChange}
                        value={employeeEditData?.designation}
                      >
                        <option disabled value="">
                          Please select a Designation
                        </option>
                        <option value="admin">Admin</option>
                        <option value="sales">Sales</option>
                        <option value="purchase">Purchase</option>
                        <option value="orders">
                          Order Handling and Production
                        </option>
                        <option value="store">Store</option>
                        <option value="storepurchase">Store & Purchase</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="department"
                        className="form-label fw-bold"
                      >
                        Department
                      </label>
                      <select
                        className="form-select"
                        id="department"
                        name="department"
                        value={employeeEditData.department}
                        onChange={handleInputChange}
                      >
                        <option value="">--Select Department--</option>
                        <option value="admin">Admin</option>
                        <option value="sales">Sales</option>
                        <option value="purchase">Purchase</option>
                        <option value="orders">
                          Order Handling and Production
                        </option>
                        <option value="store">Store</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="address" className="form-label fw-bold">
                        Address
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="address"
                        name="address"
                        value={employeeEditData.address}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="phone_number"
                        className="form-label fw-bold"
                      >
                        Phone Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="phone_number"
                        name="phone_number"
                        value={employeeEditData.phone_number}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="job_title" className="form-label fw-bold">
                        Job Title
                      </label>

                      <select
                        id="job_title"
                        name="job_title"
                        className="form-control"
                        onChange={(e) => {
                          handleJobTitleChange(e); // Your existing function to update local state
                          // handleInputChange();
                        }}
                        value={employeeEditData?.job_title}
                      >
                        <option value="">Please select Job Title</option>
                        {salaryMaster.map((item, key) => (
                          <option key={key} value={item.job_title}>
                            {item.job_title}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="base_salary"
                        className="form-label fw-bold"
                      >
                        Base Salary
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="base_salary"
                        name="base_salary"
                        value={employeeEditData.base_salary}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="hra" className="form-label fw-bold">
                        HRA
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="hra"
                        name="hra"
                        value={employeeEditData.hra}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="allowances"
                        className="form-label fw-bold"
                      >
                        Allowance
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="allowances"
                        name="allowances"
                        value={employeeEditData.allowances}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="gross_salary"
                        className="form-label fw-bold"
                      >
                        Gross Salary
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="gross_salary"
                        name="gross_salary"
                        value={employeeEditData.gross_salary}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="net_salary"
                        className="form-label fw-bold"
                      >
                        Net Salary
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="net_salary"
                        name="net_salary"
                        value={employeeEditData.net_salary}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="bonus" className="form-label fw-bold">
                        Bonus
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="bonus"
                        name="bonus"
                        value={employeeEditData?.bonus}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="work_start_time"
                        className="form-label fw-bold"
                      >
                        Work Start Time
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="work_start_time"
                        name="work_start_time"
                        value={employeeEditData.work_start_time}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="work_end_time"
                        className="form-label fw-bold"
                      >
                        Work End Time
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="work_end_time"
                        name="work_end_time"
                        value={employeeEditData.work_end_time}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="shift_type"
                        className="form-label fw-bold"
                      >
                        Shift Type
                      </label>
                      <select
                        className="form-select"
                        id="shift_type"
                        name="shift_type"
                        value={employeeEditData?.shift_type}
                        onChange={handleInputChange}
                      >
                        <option value="" disabled>
                          --Select Shift--
                        </option>
                        <option value="morning">Morning</option>
                        <option value="evening">Evening</option>
                        <option value="night">Night</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="deductions"
                        className="form-label fw-bold"
                      >
                        User Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="deductions"
                        name="deductions"
                        value={employeeEditData?.username}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="status" className="form-label fw-bold">
                        Status
                      </label>
                      <select
                        className="form-select"
                        id="status"
                        name="status"
                        value={employeeEditData?.status}
                        onChange={handleInputChange}
                      >
                        <option value="" disabled>
                          --Select Status--
                        </option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                        <option value="pending">Pending</option>
                        <option value="terminated">Terminated</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="deductions"
                        className="form-label fw-bold"
                      >
                        Deductions
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="deductions"
                        name="deductions"
                        value={employeeEditData.deductions}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="status" className="form-label fw-bold">
                        Select Currency
                      </label>
                      <select
                        className="form-select"
                        id="currency"
                        name="currency"
                        value={employeeEditData?.currency}
                        onChange={handleInputChange}
                      >
                        <option value="">Select Currency</option>
                        {currencyCodes?.map((currency, index) => (
                          <option
                            key={`${currency.AlphabeticCode}-${index}`}
                            value={currency.AlphabeticCode}
                          >
                            {currency.Currency} ({currency.AlphabeticCode})
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="roles" className="form-label fw-bold">
                        Roles
                      </label>
                      <Select
                        id="roles"
                        isMulti
                        value={selectedRoles}
                        options={roles}
                        onChange={handleRoleChange}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </div>
                  </div>
                </div>
              </form>{" "}
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              {mode === "update" ? "Save changes" : "Add Employee"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeModal;
