// EmployeeAboutSection.js
import React from "react";
import { SimpleCard } from "@gull";

const EmployeeAboutSection = ({ employeeDetails }) => {
  if (!employeeDetails) return <p>Loading about information...</p>;
  const activeSalary = employeeDetails.employee_salary_mappers?.find(salary => salary.status === "active");


  console.log("show active salary:",activeSalary);

  return (
    <div className="row" >
      {/* Left Card - Personal Information */}
      <div className="col-md-6">
        <SimpleCard style={{ padding: "20px"}}>
          <h5 style={{ borderBottom: "1px solid lightgrey", paddingBottom: "10px" }}>
            PERSONAL INFORMATION
          </h5>
          <div className="d-flex align-items-center mb-3">
            <div className="avatar-circle1" style={{ fontSize: "1em", fontWeight: "bold", marginRight: "15px" }}>
              {employeeDetails.first_name[0]}
              {employeeDetails.last_name[0]}
            </div>
            <div>
              <p style={{ margin: 0 }}>
                <strong>{employeeDetails.first_name} {employeeDetails.last_name}</strong>
              </p>
              <p style={{ margin: 0 }}>{employeeDetails.email_address}</p>
              <p>
                <i className="fa fa-phone mr-2" aria-hidden="true"></i> {employeeDetails.phone_number}
              </p>
            </div>
          </div>
          <p><strong>Address:</strong> {employeeDetails.address}</p>
        </SimpleCard>
      </div>

      {/* Right Card - Work Information */}
      <div className="col-md-6">
        <SimpleCard style={{ padding: "20px" }}>
          <h5 style={{ borderBottom: "1px solid lightgrey", paddingBottom: "10px" }}>
            WORK INFORMATION
          </h5>
          <p><strong>Designation:</strong> {employeeDetails.designation}</p>
          <p><strong>Department:</strong> {employeeDetails.department}</p>
          <p><strong>Roles:</strong> {employeeDetails.roles?.map(role => <span key={role.id}>{role.role_name} </span>) || "No roles assigned"}</p>
          <p><strong>Status:</strong> {employeeDetails.status}</p>

          {activeSalary ? (
            <>
              <p><strong>Shift Type:</strong> {activeSalary?.shift_type || "N"}</p>
              <p><strong>Job Title:</strong> {activeSalary?.salary_master?.job_title || "N"}</p>
              <p><strong>Salary Structure:</strong> Base Salary: {activeSalary.base_salary}, HRA: {activeSalary.hra}, Allowances: {activeSalary.allowances}, Net Salary: {activeSalary.net_salary}</p>
            </>
          ) : (
            <p>No active salary information available</p>
          )}

        </SimpleCard>
      </div>
    </div>
  );
};

export default EmployeeAboutSection;
