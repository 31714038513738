import axios from "axios";
import styled from "styled-components";
import { SimpleCard } from "@gull";
import { Breadcrumb } from "@gull";
import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { serverAddress } from "constants";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space } from "antd";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Form } from "react-bootstrap";
import { Checkbox } from "antd";
import UpdateRemarksModal from "../Common/UpdateRemarksModal";
import { fetchLeadListApi } from "app/API/LeadAPI";
import ClientInformation from "../client/ClientInformation";
import { BsCheckLg } from "react-icons/bs";
import { CloseOutlined } from "@ant-design/icons";
import { fetchEmployees } from "app/API/EmployeeAPI";
import "../../../styles/global.css";

import Swal from "sweetalert2";
import { MdDelete } from "react-icons/md";

const LeadList = (props) => {
  const [state, setState] = useState({
    leadList: [],
    totalLeads: 0,
  });

  const navigate = useNavigate();
  const [totalLeads, setTotalLeads] = useState(0);
  const [deleteLeadId, setDeleteLeadId] = useState(null);
  const [deleteInput, setDeleteInput] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [showUpdateStatusModal, setShowUpdateStatusModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");

  const [showUpdateRemarksModal, setShowUpdateRemarksModal] = useState(false);
  const [updatedRemarks, setUpdatedRemarks] = useState("");
  const [updateRemarksLeadId, setUpdateRemarksLeadId] = useState(null);
  const [selectedHandledBy, setSelectedHandledBy] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");

  const storedToken = localStorage.getItem("token");

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  // Add state variables for filter options
  const [filterOptions, setFilterOptions] = useState({
    "Quotation Pending": false,
    "Quotation Sent": false,
    "Waiting for Customer Response": false,
    "Revised Quotation to be Sent": false,
    "Order Closed with Logway": false,
    "Order Closed with Other Supplier": false,
    "Project Delayed": false,
  });
  const employeeOptions =
    employeeList?.map((employee) => ({
      label: `${employee.first_name} ${employee.last_name}`,
      value: `${employee.first_name} ${employee.last_name}`,
      id: `${employee.id}`,
    })) || [];

  const handleDropdownVisibleChange = (flag) => {
    setDropdownVisible(flag);
  };

  const handleCloseDropdown = () => {
    setDropdownVisible(false); // Close the dropdown when the cross icon is clicked
  };

  console.log("show state", state);
  

  const handleInputChange = (e) => {
    setConfirmationText(e.target.value.toLowerCase());
  };

  const handleDeleteClick = () => {
    if (confirmationText === "delete") {
      handleDeleteLead();
    } else {
      alert("Please type 'delete' to confirm");
    }
  };
  // filters

  const handleFilterChange = (option) => {
    setCurrentPage(1);
    setFilterOptions((prevOptions) => {
      const newOptions = { ...prevOptions, [option]: !prevOptions[option] };
      // Call fetchLeadList after updating the filter options
      fetchLeadList(newOptions, props?.uuid, selectedHandledBy,currentPage,
        itemsPerPage,
        searchQuery); // Pass selectedHandledBy to maintain the current handledBy state
      return newOptions;
    });
  };
  const handleHandledByChange = (e) => {
    setCurrentPage(1)
    const selectedHandledBy = e.target.value;
    setSelectedHandledBy(selectedHandledBy);
    // Call fetchLeadList with updated employee_id (handledBy) and current filterOptions
    fetchLeadList(filterOptions, props?.uuid, selectedHandledBy,currentPage,
      itemsPerPage,
      searchQuery); // Ensure filterOptions are not reset
  };




  const fetchEmployeeList = async () => {
    try {
      const storedToken = localStorage.getItem("token");
      const employees = await fetchEmployees(storedToken);
      setEmployeeList(employees);
      console.log(employees);
    } catch (error) {
      console.error(error.message || "Error fetching employee list");
    }
  };

  // Render the dropdown with checkboxes
  const renderFilterDropdown = () => (
    <Dropdown
      overlay={filterMenu}
      trigger={["click"]}
      visible={dropdownVisible}
      onVisibleChange={handleDropdownVisibleChange}
    >
      <Button
        className="py-2 mx-1" // Prevents the event from bubbling up
        style={{
          backgroundColor: "#BBC5EA",
          borderColor: "#BBC5EA",
          color: "#000000",
          height: "39px",
          width: "82px",
        }}
      >
        Filter&nbsp;
        <i className="fa fa-filter" aria-hidden="true"></i>
      </Button>
    </Dropdown>
  );

  // Menu for filter dropdown
  const filterMenu = (
    <Menu>
      <Menu.Item key="close" disabled style={{ textAlign: "right" }}>
        <CloseOutlined
          onClick={handleCloseDropdown}
          style={{ cursor: "pointer" }}
        />
      </Menu.Item>
      {Object.keys(filterOptions).map((option) => (
        <Menu.Item key={option}>
          <Checkbox
            checked={filterOptions[option]}
            onChange={() => handleFilterChange(option)}
          >
            {option}
          </Checkbox>
        </Menu.Item>
      ))}
      <Menu.Item key="handled by">
        <>
          <div className="dropdown-divider" style={{ margin: "10px 0" }}></div>

          <div className="row align-items-center" style={{ padding: "10px 0" }}>
            <div
              className="col-md-4"
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "15px",
                fontWeight: "500",
                whiteSpace: "nowrap",
              }}
            >
              Handled By
            </div>

            <div className="col-md-8">
              <select
                className="form-select"
                value={selectedHandledBy}
                onChange={handleHandledByChange}
              >
                <option value="">Select Handled By</option>
                {employeeOptions.map((employee) => (
                  <option key={employee.value} value={employee.id}>
                    {employee.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </>
      </Menu.Item>
    </Menu>
  );

  const fetchLeadList = async (
    options,
    uuid = null,
    employee_id,
    page,
    limit,
    query
  ) => {
    try {
      const storedToken = localStorage.getItem("token");
      const data = await fetchLeadListApi(
        options,
        uuid,
        storedToken,
        employee_id,
        page,
        limit,
        query
      );
      const leadList = data?.leads.map((lead) => ({
        ...lead,

        client: lead.client || {},
        employee: lead.employee || {},
        employee_full_name: `${lead.employee.first_name?.trim() || ""} ${
          lead.employee.last_name?.trim() || ""
        }`.trim(),
      }));
      setState({ leadList });
      setTotalLeads(data?.totalLeads);
    } catch (error) {
      toast.error(error.message || "Error fetching lead list");
    }
  };

  const handleUpdateStatusModal = (lead) => {
    console.log("show lead turant:", lead);
    setSelectedLead(lead);
    setSelectedStatus(lead?.lead_statuses[0]?.status); // Set the initial status based on the selected order
    setShowUpdateStatusModal(true);
  };

  const menu = (record) => (
    <Menu>
      <Menu.Item key="4" onClick={() => showDeleteConfirmation(record.id)}>
        Delete Lead
      </Menu.Item>

      <Menu.Item key="5" onClick={() => handleUpdateModal(record)}>
        Lead handled by
      </Menu.Item>

      <Menu.Item key="6" onClick={() => handleUpdateStatusModal(record)}>
        Update Lead Status
      </Menu.Item>

      <Menu.Item key="7" onClick={() => handleUpdateRemarksModal(record)}>
        Update Remarks
      </Menu.Item>
    </Menu>
  );

  const showDeleteConfirmation = (leadId) => {
    setDeleteLeadId(leadId);
    setShowDeleteModal(true);
  };

  const handleDeleteLead = async () => {
    try {
      const response = await axios.delete(
        `${serverAddress}/lead/${deleteLeadId}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (response.status === 200) {
        toast.success("Lead deleted successfully");
        fetchLeadList(
          filterOptions,
          props?.uuid,
          "",
          currentPage,
          itemsPerPage,
          searchQuery
        ); // Call the function to fetch the updated lead list
      } else {
        toast.error("Failed to delete lead");
      }
    } catch (error) {
      toast.error("An error occurred while deleting the lead");
    } finally {
      setShowDeleteModal(false);
      setDeleteLeadId(null);
      setDeleteInput("");
    }
  };

  useEffect(() => {
    if (props.uuid) {
      fetchLeadList(
        filterOptions,
        props.uuid,
        selectedHandledBy,
        currentPage,
        itemsPerPage,
        searchQuery
      );
    } else {
      fetchLeadList(filterOptions, "", selectedHandledBy, currentPage, itemsPerPage, searchQuery);
    }
    fetchEmployeeList();
  }, [props.uuid, currentPage, itemsPerPage, searchQuery]);

  const handleUpdateModal = (lead) => {
    setSelectedLead(lead);
    setSelectedEmployeeId(lead.employee.id);
    setShowUpdateModal(true);
  };

  console.log("show search query:", searchQuery);
  const handleUpdateLead = async () => {
    try {
      const { uuid } = selectedLead;
      const response = await axios.put(
        `${serverAddress}/lead/update/handled_by`,
        {
          employee_id: selectedEmployeeId,
          uuid,
          token: localStorage.getItem("token"),
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (response.status === 200) {
        toast.success("Lead updated successfully");
        fetchLeadList(
          filterOptions,
          props?.uuid,
          "",
          currentPage,
          itemsPerPage,
          searchQuery
        ); // Call the function to fetch the updated lead list
      } else {
        toast.error("Failed to update lead");
      }
    } catch (error) {
      toast.error("An error occurred while updating the lead");
    } finally {
      setShowUpdateModal(false);
      setSelectedLead(null);
      setSelectedEmployeeId(null);
    }
  };

  const handleUpdateStatus = async () => {
    try {
      if (!selectedStatus) {
        toast.error("Please select a status before updating");
        return;
      }

      const { id } = selectedLead;
      const response = await axios.put(
        `${serverAddress}/lead/update/lead-status`,
        {
          status: selectedStatus,
          id,
          token: localStorage.getItem("token"),
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (response.status === 200) {
        toast.success("Lead status updated successfully");
        fetchLeadList(
          filterOptions,
          props?.uuid,
          "",
          currentPage,
          itemsPerPage,
          searchQuery
        ); // Call the function to fetch the updated lead list
      } else {
        toast.error("Failed to update lead status");
      }
    } catch (error) {
      toast.error("An error occurred while updating the lead status");
    } finally {
      setShowUpdateStatusModal(false);
      setSelectedLead(null);
      setSelectedStatus("");
    }
  };

  const { leadList } = state;
  const { SearchBar } = Search;

  const handleAddLead = () => {
    // Replace "/add-lead" with the actual path to your add lead component
    navigate("/lead/create");
  };

  const handleUpdateRemarksModal = (lead) => {
    setUpdateRemarksLeadId(lead.id);
    setUpdatedRemarks(lead.lead_statuses[0].remarks || ""); // Set default value
    setShowUpdateRemarksModal(true);
  };

  const handleUpdateRemarks = async () => {
    try {
      // Validate remarks
      if (!updatedRemarks.trim()) {
        toast.error("Remarks cannot be empty");
        return;
      }
      const response = await axios.post(
        `${serverAddress}/lead/add/remark`,
        {
          id: updateRemarksLeadId,
          remarks: updatedRemarks,
          token: localStorage.getItem("token"),
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (response.data && response.data.updated) {
        toast.success("Remarks updated successfully");
        fetchLeadList(
          filterOptions,
          props?.uuid,
          "",
          currentPage,
          itemsPerPage,
          searchQuery
        ); // Fetch the updated lead list
      } else {
        toast.error("Failed to update remarks");
      }
    } catch (error) {
      toast.error("An error occurred while updating remarks");
    } finally {
      setShowUpdateRemarksModal(false);
      setUpdateRemarksLeadId(null);
      setUpdatedRemarks("");
    }
  };
  const handleExportDoc = (e) => {
    // console.log("line 567:", storedToken);
    const exportUrl = `${serverAddress}/lead/export/doc?folderName=lead`;

    axios({
      method: "post",
      url: exportUrl,
      responseType: "json",
      headers: {
        Authorization: `Bearer ${storedToken}`,
      },
    })
      .then((response) => {
        const downloadUrl = response.data.url;

        // Trigger download using the provided URL
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "client-data.csv");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: "Failed to export document",
          type: "error",
          icon: "error",
          timer: 1500,
        });
      })
      .finally(() => {});
  };
 

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const totalPages = Math.ceil(totalLeads / itemsPerPage);

  console.log("show total Clients:", totalLeads);

  const getVisiblePages = () => {
    const pageRange = 5; // Number of pages to display
    const start = Math.max(1, currentPage - Math.floor(pageRange / 2));
    const end = Math.min(totalPages, start + pageRange - 1);
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value); // Update searchQuery state
    setCurrentPage(1); // Reset to first page when starting a new search
  };

  return (
    <div>
      <Breadcrumb
        routeSegments={[
          { name: "Dashboard", path: "/dashboard/v1" },
          { name: "Lead List" },
        ]}
      />
      <SimpleCard>
        <ToolkitProvider
          keyField="id"
          data={leadList}
          search
        >
          {(props) => (
            <div>
              <div className="d-flex justify-content-between align-items-center mb-4 ">
                <div className="w-96">
                  <input
                    type="text"
                    placeholder="Search by name or vendor name"
                    className="search-bar"
                    value={searchQuery} // Bind searchQuery state to input
                    onChange={handleSearchChange} // Update search query on input change
                  />
                </div>

                <div className="d-flex align-items-center">
                  <Button
                    variant="primary"
                    onClick={handleAddLead}
                    className=" py-2"
                  >
                    <i className="i-Add-User mr-1 "></i> Add Lead
                  </Button>

                  <Button
                    style={{
                      backgroundColor: "#68C281",
                      borderColor: "#68C281",
                      height: "39px",
                      width: "98px",
                    }}
                    className="mx-2 py-2"
                    variant="primary"
                    onClick={handleExportDoc}
                  >
                    <i className="i-File-Download mr-1 mx-1"></i>
                    Export
                  </Button>
                  {renderFilterDropdown()}
                </div>
              </div>

              {/* <BootstrapTable
                bordered={false}
                {...props.baseProps}
                pagination={paginationFactory(paginationOptions)}
                rowEvents={rowEvents}
              /> */}

              <table className="table common-table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Client Name</th>
                    <th>Lead ID</th>
                    <th>Subject</th>
                    <th>Type</th>
                    <th>Handled By</th>
                    <th>Remarks</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {leadList.length > 0 ? (
                    leadList.map((lead, index) => (
                      <tr key={lead?.id}>
                        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                        <td>{lead?.client?.company_name}</td>
                        <td>L-{lead?.lead_code}</td>
                        <td>{lead?.item_subject}</td>
                        <td>{lead?.project_type}</td>
                        <td>
                          {lead?.employee?.first_name}{" "}
                          {lead?.employee?.last_name}
                        </td>
                        <td>{lead?.lead_statuses[0]?.remarks}</td>
                        <td>{lead?.lead_statuses[0]?.status}</td>
                        <td>
                          <Dropdown overlay={menu(lead)} trigger={["click"]}>
                            <a className="" onClick={(e) => e.preventDefault()}>
                              Options <DownOutlined />
                            </a>
                          </Dropdown>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="9">No data available</td>
                    </tr>
                  )}
                </tbody>
              </table>

              <div className="pagination-container">
                <div className="items-per-page">
                  <label>Items per page: </label>
                  <select
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                </div>

                <div className="pagination-controls">
                  <button
                    onClick={() => handlePageChange(1)}
                    disabled={currentPage === 1}
                  >
                    First
                  </button>
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Prev
                  </button>

                  {getVisiblePages().map((page) => (
                    <button
                      key={page}
                      className={currentPage === page ? "active" : ""}
                      onClick={() => handlePageChange(page)}
                    >
                      {page}
                    </button>
                  ))}

                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                  <button
                    onClick={() => handlePageChange(totalPages)}
                    disabled={currentPage === totalPages}
                  >
                    Last
                  </button>
                </div>
              </div>
            </div>
          )}
        </ToolkitProvider>
      </SimpleCard>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Lead ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete the lead ?</p>
          <p>Type "delete" in the input below to confirm:</p>
          <input type="text" onChange={handleInputChange} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={handleDeleteClick}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* update handled by modal */}

      <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Lead Handled by</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="employeeSelect">
            <Form.Label>Select Employee:</Form.Label>
            <Form.Select
              as="select"
              value={selectedEmployeeId}
              onChange={(e) => setSelectedEmployeeId(e.target.value)}
            >
              {employeeList.map((employee) => (
                <option key={employee.id} value={employee.id}>
                  {`${employee.first_name} ${employee.last_name}`}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowUpdateModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateLead}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      {/* update lead status modal */}

      <Modal
        show={showUpdateStatusModal}
        onHide={() => setShowUpdateStatusModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Lead Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="statusSelect">
            <Form.Label>Select Status:</Form.Label>
            <Form.Select
              as="select"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <option value="">Select status</option>
              <option value="Quotation Pending">Quotation Pending</option>
              <option value="Quotation Sent">Quotation Sent</option>
              <option value="Waiting for Customer Response">
                Waiting for Customer Response
              </option>
              <option value="Revised Quotation to be Sent">
                Revised Quotation to be Sent
              </option>
              <option value="Order Closed with Power Tech">
                Order Closed with Power Tech
              </option>
              <option value="Order Closed with Other Supplier">
                Order Closed with Other Supplier
              </option>
              <option value="Project Delayed">Project Delayed</option>
            </Form.Select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowUpdateStatusModal(false)}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateStatus}>
            Update Status
          </Button>
        </Modal.Footer>
      </Modal>

      <UpdateRemarksModal
        show={showUpdateRemarksModal}
        handleClose={() => setShowUpdateRemarksModal(false)}
        remarks={updatedRemarks}
        handleChange={(e) => setUpdatedRemarks(e.target.value)}
        handleUpdate={handleUpdateRemarks}
      />
    </div>
  );
};

export default LeadList;
