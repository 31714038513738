import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import codes from "../countryList";

const UpdateClientModal = ({
  show,
  handleClose,
  formData,
  handleFormChange,
  handleSubmit,
}) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update Client Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <label htmlFor="company_name" className="form-label">
            Company Name
          </label>
          <input
            type="text"
            className="form-control"
            id="company_name"
            name="company_name"
            value={formData.company_name}
            onChange={handleFormChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="billing_address" className="form-label">
            Billing Address
          </label>
          <input
            type="text"
            className="form-control"
            id="billing_address"
            name="billing_address"
            value={formData.billing_address}
            onChange={handleFormChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="shipping_address" className="form-label">
            Shipping Address
          </label>
          <input
            type="text"
            className="form-control"
            id="shipping_address"
            name="shipping_address"
            value={formData.shipping_address}
            onChange={handleFormChange}
          />
        </div>
        {/* <div className="mb-3">
          <label htmlFor="type" className="form-label">
            Type
          </label>
          <input
            type="text"
            className="form-control"
            id="type"
            name="type"
            value={formData.type}
            onChange={handleFormChange}
          />
        </div> */}
        <div className="mb-3">
          <label htmlFor="category" className="form-label">
            Category
          </label>
          <select
            name="category"
            className="form-select"
            id="category"
            value={formData.category}
            onChange={handleFormChange}
          >
            <option value="B2B">B2B</option>
            <option value="B2C">B2C</option>
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="gstin_number" className="form-label">
            GSTIN Number
          </label>
          <input
            type="text"
            className="form-control"
            id="gstin_number"
            name="gstin_number"
            value={formData.gstin_number}
            onChange={handleFormChange}
          />
        </div>
        {/* <div className="mb-3">
          <label htmlFor="client_code" className="form-label">
            Client Code
          </label>
          <input
            type="text"
            className="form-control"
            id="client_code"
            name="client_code"
            value={formData.client_code}
            onChange={handleFormChange}
          />
        </div> */}
        <div className="mb-3">
          <label htmlFor="country" className="form-label">
            Country
          </label>
          <select
            name="country"
            className="form-select"
            id="country"
            value={formData.country}
            onChange={handleFormChange}
          >
            {codes.map((country) => (
              <option key={country.code} value={country.name}>
                {country.name}
              </option>
            ))}
          </select>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateClientModal;
